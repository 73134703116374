import React, { useState, useEffect } from "react";
import { Search, Table } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { getLogs } from "App/Admin/_graphql/queries/log";
import { getColumns } from "./_data/Columns";
import { getCrumbs } from "../_helpers/crumbs";

const defaultParams = {
  results: 10,
  page: 1,
  sortField: "createdAt",
  sortOrder: "descend",
};

function Logs(props) {
  const client = useApolloClient();
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    pagination: { pageSize: 10, showSizeChanger: false },
  });
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();

  // Load Payments
  const fetchLogs = async (params = defaultParams) => {
    const queryData = await client.query({
      variables: {
        id: props?.applicationId,
        ...params,
        search: search,
      },
      query: getLogs,
    });
    const pagination = { ...state.pagination };
    pagination.total = queryData?.data?.getLogs?.pageTotal;
    pagination.totalAll = queryData?.data?.getLogs?.total;
    setPage(params?.page || 1);
    props?.setCurrentCount(pagination.totalAll);
    setState({
      loading: false,
      data: queryData?.data?.getLogs?.data || [],
      pagination: pagination,
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...state.pagination };
    pager.current = pagination.current;
    setState({ pagination: pager });
    fetchLogs({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      search: search,
    });
  };

  useEffect(() => {
    props?.setCurrentCount(0);
    props.setCrumbs(getCrumbs("logs"));
    fetchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startResults = (page - 1) * defaultParams?.results + 1;
  const endResults = startResults + state?.data?.length - 1;

  const columns = getColumns();

  return (
    <div className="admin-application-logs">
      <div className="admin-search admin-search-row">
        <Search
          placeholder="Search logs..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onSearch={() => {
            fetchLogs({ ...defaultParams, search });
          }}
          style={{ width: 300 }}
        />
      </div>
      <div className="admin-table application-table">
        <Table
          columns={columns}
          loading={state.loading}
          pagination={state.pagination}
          onChange={handleTableChange}
          rowKey={(record) => record.id}
          dataSource={state?.data || []}
          showSorterTooltip={false}
          scroll={{
            y: "calc(100vh - 317px)",
            x: "max-content",
          }}
          locale={{
            emptyText: state.loading ? "Loading logs..." : "No logs found",
          }}
        />
      </div>
      {state?.data?.length > 0 && (
        <div className="admin-pagination">
          Showing {startResults}-{endResults} of {state?.pagination?.total || 0}{" "}
        </div>
      )}
    </div>
  );
}

export default Logs;
