import { gql } from "@apollo/client";

export const getCustomFields = gql`
  query {
    getCustomFields {
      id
      name
      type
      description
      category
      archived
      createdAt
      updatedAt
    }
  }
`;

export const getCustomField = gql`
  query getCustomField($custom_field_id: ID) {
    getCustomField(custom_field_id: $custom_field_id) {
      name
      type
      description
      category
      archived
    }
  }
`;

export const applicationCustomFields = gql`
  query applicationCustomFields($id: String) {
    applicationCustomFields(id: $id) {
      data {
        id
        name
        type
        description
        category
        archived
      }
      value
    }
  }
`;
