import { gql } from "@apollo/client";

export const getSelfEnrollment = gql`
  query {
    getSelfEnrollment {
      title
      logo_url

      primary_font
      secondary_font
      primary_color
      secondary_color

      phone_number
      redirect_url
      contact_text

      order
      status
      settings {
        screening {
          visible
          questions {
            question
            options
            accepted_response
          }
        }
        email {
          visible
          title
        }
        personal {
          visible
          title
          fields
        }
        phone {
          visible
          title
        }
        location {
          visible
          title
        }
        credit_report {
          visible
        }
        credit_select {
          visible
          title
          verify_title
          minimum_selected_amount
          minimum_debt_amount
        }
        plan {
          visible
          title
        }
        payment_scheduling {
          visible
          title
          subtitle
        }
        payment_info {
          visible
          title
        }
        review {
          visible
          title
          subtitle
        }
        agreement {
          visible
          content
        }
      }
    }
  }
`;
