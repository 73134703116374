import { useState } from "react";
import { Button, message, Modal } from "@centrate-io/barn";
import { getErrors } from "_helpers/api";
import { IconWebhook } from "@tabler/icons-react";
import { FormInput, InputLabel, Flex, AlertBox } from "_styleguide";
import { CallsClient } from "_graphql/clients/CallsClient";
import { setupWebhookIntegration } from "_graphql/mutations/integration";
import "./ForthSetup.scss";

function ForthSetup(props) {
  const callsClient = CallsClient;
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [postURL, setPostURL] = useState(undefined);

  const setupForth = async () => {
    setSaving(true);
    try {
      await callsClient.mutate({
        variables: {
          type: "webhook-leads-forth",
          settings: JSON.stringify({
            url: postURL,
          }),
        },
        mutation: setupWebhookIntegration,
      });
      message.success(`Forth integration setup successfully`);
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(
        getErrors(err) || "Error setting up integration, try again"
      );
      setSaving(false);
    }
  };

  return (
    <Modal
      wrapClassName="forth-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={600}
    >
      <div className="pp-modal-header">
        <div>
          <IconWebhook />
        </div>
        <h3>Setup Forth</h3>
      </div>

      <Modal.Body>
        <Flex vertical={true}>
          <InputLabel htmlFor="postURL">Post URL *</InputLabel>
          <FormInput
            id="postURL"
            value={postURL}
            disabled={saving}
            onChange={(e) => setPostURL(e.target.value)}
            placeholder="e.g. https://login.forthcrm.com/post/YOUR_KEY_GOES_HERE/"
            size="medium"
          />
        </Flex>
        <AlertBox type="info">
          To send a lead/customer data to Forth, you need to{" "}
          <b>setup a trigger</b> and select that action for{" "}
          <b>Integrations &gt; Send to Forth</b>. You can select the trigger to
          determine at what time you want data sent over.
        </AlertBox>
      </Modal.Body>

      <Modal.Actions>
        <Button block type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button
          block
          type="primary"
          disabled={postURL?.length <= 0}
          onClick={setupForth}
          loading={saving}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ForthSetup;
