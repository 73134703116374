import React from "react";
import { pageMap } from "../../_helpers";
import { Flex, InputLabel, FormInput } from "_styleguide";

function Email(props) {
  const { settings, us } = props;

  const s = settings.settings.email;

  return (
    <div className="se-form">
      <h3>{pageMap["email"]?.title}</h3>
      <div className="se-form-items">
        <Flex vertical>
          <InputLabel htmlFor="title">Page Title:</InputLabel>
          <FormInput
            id="title"
            value={s.title}
            onChange={(e) => us("email", "title", e.target.value)}
            size="medium"
          />
        </Flex>
      </div>
    </div>
  );
}

export default Email;
