import { Text } from "@centrate-io/barn";
import { IconCalendar } from "@tabler/icons-react";
import moment from "moment";
export const getColumns = () => {
  return [
    {
      title: <div className="column-name">Type</div>,
      dataIndex: "type",
      key: "type",
      sorter: true,
      render: (type, data) => (
        <div>
          <Text className="doc-name">
            <b>{type?.toUpperCase()}</b>
          </Text>
          {data?.trigger?.name ? (
            <Text className="helper">
              <i>{data?.trigger?.name}</i>
            </Text>
          ) : null}
        </div>
      ),
    },
    {
      title: <div className="column-name">Category</div>,
      dataIndex: "category",
      key: "category",
      sorter: true,
      render: (category) => {
        return <Text>{category}</Text>;
      },
    },
    {
      title: <div className="column-name">Sub-Category</div>,
      dataIndex: "sub_category",
      key: "sub_category",
      sorter: true,
      render: (sub_category) => {
        return <Text>{sub_category}</Text>;
      },
    },
    {
      title: <div className="column-name">Success</div>,
      dataIndex: "success",
      key: "success",
      sorter: true,
      width: 120,
      render: (success) => {
        return (
          <div>
            <Text>
              <b>{success ? "Yes" : "No"}</b>
            </Text>
          </div>
        );
      },
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Created
        </div>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      sorter: true,
      defaultSortOrder: "descend",
      render: (createdAt) => (
        <Text>{moment(parseInt(createdAt)).format("M/D/YY @ h:mm a")}</Text>
      ),
    },
  ];
};
