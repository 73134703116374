import { useState } from "react";
import ForthSVG from "_assets/logos/forth.svg";
import { IconPlugConnected, IconFileArrowRight } from "@tabler/icons-react";
import { Button } from "@centrate-io/barn";
import { Tag } from "_styleguide";
import ForthSetup from "../../Webhooks/_modals/ForthSetup/ForthSetup";

const WebhookLeadsForthConnect = (props) => {
  const [modal, setModal] = useState(false);
  return (
    <>
      {modal ? (
        <ForthSetup
          reload={props.reload}
          user={props.user}
          removeModal={() => setModal(false)}
        />
      ) : null}
      <Button className="boxed" type="primary" onClick={() => setModal(true)}>
        <IconPlugConnected /> Connect Forth
      </Button>
    </>
  );
};

const WebhookLeadsForth = {
  type: "webhook-leads-forth",
  category: "webhook",
  company_only: true,
  contact_only: false,
  logo: ForthSVG,
  name: (
    <span>
      Export to Forth CRM
      <Tag type="neutral">
        <IconFileArrowRight /> Outgoing
      </Tag>
    </span>
  ),
  description:
    "Send your leads to Forth CRM, setup a trigger when you would like them sent",
  Connect: WebhookLeadsForthConnect,
};

export default WebhookLeadsForth;
