import { useState } from "react";
import { Button, message, Modal } from "@centrate-io/barn";
import { setupBlueRock } from "_graphql/mutations/integration";
import { getErrors } from "_helpers/api";
import { IconPhone } from "@tabler/icons-react";
import { FormInput, InputLabel, Flex } from "_styleguide";
import { CallsClient } from "_graphql/clients/CallsClient";
import "./BlueRockSetup.scss";

function BlueRockSetup(props) {
  const callsClient = CallsClient;
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [username, setUsername] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const addBlueRock = async () => {
    setSaving(true);
    try {
      await callsClient.mutate({
        variables: {
          username: username,
          password: password,
        },
        mutation: setupBlueRock,
      });
      message.success(`BlueRock integration setup successfully`);
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(
        getErrors(err) || "Error setting up integration, try again"
      );
      setSaving(false);
    }
  };

  return (
    <Modal
      wrapClassName="bluerock-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      <div className="pp-modal-header">
        <div>
          <IconPhone />
        </div>
        <h3>Setup BlueRock</h3>
      </div>

      <Modal.Body>
        <Flex vertical={true}>
          <InputLabel htmlFor="username">Username *</InputLabel>
          <FormInput
            id="username"
            value={username}
            disabled={saving}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="e.g. company_name"
            size="medium"
          />
        </Flex>
        <Flex vertical={true}>
          <InputLabel htmlFor="password">Password *</InputLabel>
          <FormInput
            id="password"
            value={password}
            disabled={saving}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="e.g. XXXXXXX"
            size="medium"
          />
        </Flex>
      </Modal.Body>

      <Modal.Actions>
        <Button block type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button
          block
          type="primary"
          disabled={username?.length <= 0 || password?.length <= 0}
          onClick={addBlueRock}
          loading={saving}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default BlueRockSetup;
