import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import { message } from "@centrate-io/barn";
import Information from "./Information/Information";
import Credit from "./Credit/Credit";
import Payment from "./Payment/Payment";
import Plan from "./Plan/Plan";
import Review from "./Review/Review";
import BankAgreement from "./BankAgreement/BankAgreement";
import Budget from "./Budget/Budget";
import Header from "./_components/Header/Header";
import Footer from "./_components/Footer/Footer";
import Menu from "./_components/Menu/Menu";
import { getCreditReport } from "App/Admin/_graphql/queries/creditreport";
import { applicationCustomFields } from "App/Admin/_graphql/queries/custom_field";
import { FormatPhone } from "_assets/js/helpers";
import { getForm } from "App/Admin/Wizard/_helpers/form";
import { Loading } from "_styleguide";
import "./Wizard.scss";

const DEFAULT_TAB = "information";

function Wizard(props) {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { applicationId, tab } = useParams();
  const [form, setForm] = useState({});
  const [savedForm, setSavedForm] = useState("{}");
  // eslint-disable-next-line
  const [savedTradelines, setSavedTradelines] = useState("[]");
  const [bank, setBank] = useState();
  const [savedBudgetItems, setSavedBudgetItems] = useState("[]");
  const [tradelines, setTradelines] = useState([]);
  const [transitioning, setTransitioning] = useState(true);
  const [report, setReport] = useState();
  const [paymentEntryType, setPaymentEntryType] = useState(false);
  const [wasCancelled, setWasCancelled] = useState(false);
  const [budgetItems, setBudgetItems] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [savedCustomFields, setSavedCustomFields] = useState();
  const [customFieldsLoading, setCustomFieldsLoading] = useState(true);

  const fetchReport = async (applicationId) => {
    const reportData = await client.query({
      variables: {
        application_id: applicationId,
      },
      query: getCreditReport,
    });
    const report = reportData?.data?.getCreditReport?.report || null;
    if (report) {
      try {
        const tempReport = JSON.parse(report);
        tempReport.createdAt = reportData?.data?.getCreditReport?.createdAt;
        tempReport.pdfUrl = reportData?.data?.getCreditReport?.pdf_url;
        setReport(tempReport || null);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getApplicationCustomFields = async () => {
    const response = await client.query({
      variables: { id: applicationId },
      query: applicationCustomFields,
    });
    setCustomFields(response?.data?.applicationCustomFields);
    setSavedCustomFields(
      JSON.stringify(response?.data?.applicationCustomFields)
    );
    setCustomFieldsLoading(false);
  };

  const globalProps = {
    applicationId,
    tab: tab || DEFAULT_TAB,
    form: form,
    report: report,
    bank: bank,
    fetchReport: fetchReport,
    paymentEntryType: paymentEntryType,
    wasCancelled,
    setSavedForm,
    setSavedCustomFields,
    setTransitioning,
    credit: {
      tradelines,
      setTradelines,
      setSavedTradelines,
    },
    budget: {
      items: budgetItems,
      setBudgetItems,
      setSavedBudgetItems,
    },
    customFields,
    savedCustomFields,
    getApplicationCustomFields,
    savedData: JSON.parse(savedForm),
    client,
    u: (f, v) => {
      const n = { ...form };
      n[f] = f === "phone" ? FormatPhone(v) : v;
      setForm(n);
    },
    ua: (oldForm, values) => {
      setForm({ ...oldForm, ...values });
    },
    uc: (index, v) => {
      const n = [...customFields];
      n[index].value = v;
      setCustomFields(n);
    },
    isEdited:
      JSON.stringify(form) !== savedForm ||
      tab === "credit" ||
      JSON.stringify(budgetItems.sort()) !== savedBudgetItems,
  };

  useEffect(() => {
    const getInitialForm = async () => {
      if (applicationId === "new" && tab !== "information") {
        navigate("/wizard/new");
      }
      let formData = await getForm(client, applicationId);
      if (formData?.short_id && formData?.status === "subscription") {
        message.info(
          "This customer has already subscribed, you can manage their subscription below."
        );
        navigate(`/customer/${formData.short_id}`);
      } else if (applicationId !== "new" && !formData?.currentForm) {
        navigate("/wizard/new");
      } else {
        const appForm = formData?.currentForm || {};
        setForm(appForm);
        setWasCancelled(formData?.wasCancelled);
        setBank(formData?.bank || null);
        setPaymentEntryType(formData?.paymentEntryType || "plaid");
        setSavedForm(JSON.stringify(appForm));
      }
      setTransitioning(false);
    };
    getApplicationCustomFields();
    getInitialForm();
    if (!report && applicationId !== "new") {
      fetchReport(applicationId);
    }
    // eslint-disable-next-line
  }, [applicationId, tab, navigate, client]);

  return !form || customFieldsLoading ? (
    <Loading />
  ) : (
    <div className="admin-wizard">
      <Header {...globalProps} />
      <Menu {...globalProps} />
      {transitioning ? (
        <div className="admin-wizard-body">
          <Loading />
        </div>
      ) : (
        <div className="admin-wizard-body">
          {globalProps.tab === "information" && (
            <Information {...globalProps} />
          )}
          {globalProps.tab === "credit" && <Credit {...globalProps} />}
          {globalProps.tab === "payment" && <Payment {...globalProps} />}
          {globalProps.tab === "plan" && <Plan {...globalProps} />}
          {globalProps.tab === "budget" && <Budget {...globalProps} />}
          {globalProps.tab === "review" && <Review {...globalProps} />}
          {globalProps.tab === "customer" && <BankAgreement {...globalProps} />}
        </div>
      )}
      <Footer {...globalProps} />
    </div>
  );
}

export default Wizard;
