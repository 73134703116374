import { Text } from "@centrate-io/barn";
import { TableActions } from "_styleguide";
import { IconTrash, IconPencil, IconArchive } from "@tabler/icons-react";
import moment from "moment";

export const getColumns = (
  setManageModal,
  removeField,
  disableField,
  readOnly
) => {
  return [
    {
      title: "Field Name",
      dataIndex: "name",
      key: "name",
      render: (name, data) => (
        <Text>
          <b>
            {name}
            {data.archived ? ` (Disabled)` : ""}
          </b>
        </Text>
      ),
    },
    {
      title: "Field Type",
      dataIndex: "type",
      key: "type",
      render: (type) => {
        const lowerType = type.toLowerCase();
        return <Text className={`field-type ${lowerType}`}>{type}</Text>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description) => <Text>{description}</Text>,
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => (
        <Text>{moment(createdAt * 1).format("MMM D, YYYY")}</Text>
      ),
    },
    {
      title: <div className="column-name">&nbsp;</div>,
      dataIndex: "manage",
      key: "manage",
      sorter: false,
      width: 60,
      fixed: "right",
      render: (url, data) => {
        const items = [
          {
            label: (
              <span>
                <IconPencil /> Manage Field
              </span>
            ),
            onClick: () => setManageModal(data.id),
          },
        ];
        if (!readOnly) {
          items.push({
            label: (
              <span>
                <IconArchive />{" "}
                {data.archived ? "Enable Field" : "Disable Field"}
              </span>
            ),
            onClick: () => disableField(data.id, !data.archived),
          });
          items.push({
            label: (
              <span className="bad">
                <IconTrash /> Remove Field
              </span>
            ),
            onClick: () => removeField(data.id),
          });
        }
        return <TableActions items={items} />;
      },
    },
  ];
};
