import React, { Component } from "react";
import FontPicker from "react-fontpicker-ts-lite";
import "react-fontpicker-ts-lite/dist/index.css";
import "./SelectFont.scss";

class SelectFont extends Component {
  render() {
    return (
      <div className="pp-select-font">
        <FontPicker {...this.props} />
      </div>
    );
  }
}

export default SelectFont;
