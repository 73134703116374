import React from "react";
import { pageMap } from "../../_helpers";
import { Flex, InputLabel, FormInput } from "_styleguide";

function PaymentScheduling(props) {
  const { settings, us } = props;

  const s = settings.settings.payment_scheduling;

  return (
    <div className="se-form">
      <h3>{pageMap["payment_scheduling"]?.title}</h3>
      <div className="se-form-items">
        <Flex vertical style={{ marginBottom: "24px" }}>
          <InputLabel htmlFor="title">Page Title:</InputLabel>
          <FormInput
            id="title"
            value={s.title}
            onChange={(e) => us("payment_scheduling", "title", e.target.value)}
            size="medium"
          />
        </Flex>
        <Flex vertical>
          <InputLabel htmlFor="subtitle">Page Subtitle:</InputLabel>
          <FormInput
            id="subtitle"
            value={s.subtitle}
            onChange={(e) =>
              us("payment_scheduling", "subtitle", e.target.value)
            }
            size="medium"
          />
        </Flex>
      </div>
    </div>
  );
}

export default PaymentScheduling;
