export const pageMap = {
  theme: {
    title: "Theme & Settings",
    required: [],
  },
  personal: {
    title: "Personal Information",
    required: [],
  },
  email: {
    title: "Email",
    required: [],
  },
  phone: {
    title: "Phone",
    required: [],
  },
  location: {
    title: "Location",
    required: [],
  },
  screening: {
    title: "Screening Questions",
    required: [],
  },
  credit_report: {
    title: "Credit Report",
    required: ["location"],
  },
  credit_select: {
    title: "Credit Select",
    required: ["credit_report"],
  },
  payment_scheduling: {
    title: "Payment Scheduling",
    required: [],
  },
  review: {
    title: "Review",
    required: [],
  },
};
