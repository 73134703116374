import { gql } from "@apollo/client";

export const saveSelfEnrollment = gql`
  mutation saveSelfEnrollment($settings: SelfEnrollmentInputType!) {
    saveSelfEnrollment(settings: $settings)
  }
`;

export const publishSelfEnrollment = gql`
  mutation publishSelfEnrollment {
    publishSelfEnrollment
  }
`;
