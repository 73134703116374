import React from "react";
import { FormInput, InputLabel, TextArea, InputNumber } from "_styleguide";
import "./CustomFieldsInputs.scss";

const CustomFieldsInputs = ({ customFields, onChange }) => {
  if (!customFields || customFields.length === 0) {
    return null;
  }

  return (
    <div className="wzrd-box">
      <div className="wzrd-header">
        <h3>Custom Fields</h3>
      </div>
      <div className="wzrd-body custom-fields-inputs">
        {customFields.map((field, index) => {
          if (field.data.archived) {
            return null;
          }
          if (field.data.type === "ShortText" || field.data.type === "Text") {
            return (
              <div className="form-item" key={`custom-field-${index}`}>
                <InputLabel htmlFor={field.data.name}>
                  {field.data.name}
                </InputLabel>
                <FormInput
                  id={field.data.name}
                  value={field.value}
                  onChange={(e) => onChange(index, e.target.value)}
                  size="medium"
                  placeholder={field.data.description}
                />
              </div>
            );
          } else if (field.data.type === "LongText") {
            return (
              <div className="form-item" key={`custom-field-${index}`}>
                <InputLabel htmlFor={field.data.name}>
                  {field.data.name}
                </InputLabel>
                <TextArea
                  id={field.data.name}
                  value={field.value}
                  onChange={(e) => onChange(index, e.target.value)}
                  size="large"
                  autoSize={true}
                  placeholder={field.data.description}
                />
              </div>
            );
          } else if (field.data.type === "Number") {
            return (
              <div className="form-item" key={`custom-field-${index}`}>
                <InputLabel htmlFor={field.data.name}>
                  {field.data.name}
                </InputLabel>
                <InputNumber
                  controls={false}
                  value={field.value}
                  onChange={(value) => onChange(index, value)}
                  placeholder={field.data.description}
                />
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default CustomFieldsInputs;
