import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { IconPuzzle } from "@tabler/icons-react";
import IntegrationCard from "./_shared/IntegrationCard/IntegrationCard";
import { getIntegrations } from "App/Admin/_graphql/queries/integration";
import { useIntegrations } from "_contexts/useIntegrations";
import {
  disconnectIntegration,
  toggleIntegration,
} from "App/Admin/_graphql/mutations/integration";
import { getErrors } from "_helpers/api";
import {
  Gmail,
  Outlook,
  TwilioPhone,
  CRS,
  Mint,
  TwilioSMS,
  Plaid,
  Mailgun,
  Docusign,
  Postgrid,
  BlueRock,
} from "./_data";
import { Loading } from "_styleguide";
import { prettyPhone } from "_assets/js/helpers";
import { Modal, message } from "@centrate-io/barn";
import "./Integrations.scss";

function Integrations(props) {
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [integrations, setIntegrations] = useState([]);
  const { reloadIntegrations } = useIntegrations();

  // Load Payments
  const fetchIntegrations = async () => {
    const queryData = await client.query({
      query: getIntegrations,
    });
    setIntegrations(queryData?.data?.getIntegrations || []);
    reloadIntegrations();
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  // Disconnect
  const removeIntegration = async (integration_id) => {
    Modal.confirm({
      title: "Are you sure you want remove disconnect this integration?",
      okText: "Disconnect",
      content:
        "Warning: This will remove the integration and all associated data.  You will no longer be able to use this integration on the platform until you re-connect.",
      onOk: async () => {
        try {
          await client.mutate({
            variables: {
              integration_id: integration_id,
            },
            mutation: disconnectIntegration,
          });
          message.success(`Integration successfully disconnected`);
          fetchIntegrations();
        } catch (err) {
          message.error(
            getErrors(err) || "Error disconnecting integration, try again"
          );
        }
      },
    });
  };

  // Toggle
  const toggleActive = async (integration_id, active) => {
    Modal.confirm({
      title: `Are you sure you want ${
        active ? "de-activate" : "activate"
      } this integration?`,
      okText: active ? "De-activate" : "Activate",
      content: active
        ? "Warning: De-activating this integration will not allow you to use it on the platform.  All of your data will remain until disconnected"
        : "Activating this integration will allow you to use it on the platform immediately",
      onOk: async () => {
        try {
          await client.mutate({
            variables: {
              integration_id: integration_id,
            },
            mutation: toggleIntegration,
          });
          message.success(
            `Integration has been ${active ? "de-activated" : "activated"}`
          );
          fetchIntegrations();
        } catch (err) {
          message.error(
            getErrors(err) || "Error toggling integration, try again"
          );
        }
      },
    });
  };

  useEffect(() => {
    props?.setCurrentCount(0);
    props.setCrumbs({
      title: "Integrations",
      icon: <IconPuzzle />,
      description: "Improve your experience by connecting to our partners",
    });
    fetchIntegrations();
  }, []);

  const integrationData = (intModel) => {
    const type = intModel.type;
    if (intModel.company_only) {
      return (
        integrations.find((i) => {
          return i.type === type;
        }) || null
      );
    } else {
      return (
        integrations.find((i) => {
          return i.type === type && i.user_id === props.user?.id;
        }) || null
      );
    }
  };

  const cardProps = {
    reload: fetchIntegrations,
    toggleActive,
    removeIntegration,
    ...props,
  };

  const data = {
    mailgun: integrationData(Mailgun),
    gmail: integrationData(Gmail),
    outlook: integrationData(Outlook),
    twilio_phone: integrationData(TwilioPhone),
    crs: integrationData(CRS),
    mint: integrationData(Mint),
    twilio_sms: integrationData(TwilioSMS),
    plaid: integrationData(Plaid),
    docusign: integrationData(Docusign),
    postgrid: integrationData(Postgrid),
    bluerock: integrationData(BlueRock),
  };

  return loading ? (
    <div className="settings-integrations">
      <Loading />
    </div>
  ) : (
    <div className="settings-integrations">
      <div className="int-section">
        <div className="int-options">
          <IntegrationCard
            info={Mailgun}
            data={data.mailgun}
            settings={{
              type: "tooltip",
              info: {
                "Setup By": data?.mailgun?.settings?.name,
                Domain: data?.mailgun?.settings?.domain,
                Email: data?.mailgun?.settings?.email,
                Verified: data?.mailgun?.settings?.verified ? "True" : "False",
              },
            }}
            {...cardProps}
          />

          {/*          <IntegrationCard
            info={Gmail}
            data={data.gmail}
            settings={{
              type: "tooltip",
              info: {
                Name: data?.gmail?.settings?.name,
                Email: data?.gmail?.settings?.email,
              },
            }}
            {...cardProps}
          />
          <IntegrationCard
            info={Outlook}
            data={data.outlook}
            settings={{
              type: "tooltip",
              info: {
                Name: data?.outlook?.settings?.name,
                Email: data?.outlook?.settings?.email,
              },
            }}
            {...cardProps}
          />*/}
          <IntegrationCard
            info={TwilioPhone}
            data={data.twilio_phone}
            settings={{
              type: "tooltip",
              info: {
                "Twilio #": prettyPhone(data?.twilio_phone?.settings?.phone),
                "Incoming #": data?.twilio_phone?.settings?.callback
                  ? prettyPhone(data?.twilio_phone?.settings?.callback)
                  : "OFF",
              },
            }}
            {...cardProps}
          />
          <IntegrationCard
            info={TwilioSMS}
            data={data.twilio_sms}
            settings={{
              type: "tooltip",
              info: {
                "Twilio SMS #": prettyPhone(data?.twilio_sms?.settings?.phone),
              },
            }}
            {...cardProps}
          />
          {/*<IntegrationCard
            info={Docusign}
            data={data.docusign}
            settings={{
              type: "tooltip",
              info: {
                "Access Token": data?.docusign?.settings?.access_token
                  ? "Granted"
                  : "Invalid",
              },
            }}
            {...cardProps}
          />*/}
          <IntegrationCard
            info={Postgrid}
            data={data.postgrid}
            settings={{
              type: "tooltip",
              info: {
                "API Key": data?.postgrid?.settings?.api_key,
              },
            }}
            {...cardProps}
          />
          <IntegrationCard
            info={Plaid}
            data={data.plaid}
            settings={{
              type: "tooltip",
              info: {
                ID: data?.plaid?.settings?.client_id,
                SECRET: data?.plaid?.settings?.secret,
              },
            }}
            {...cardProps}
          />
          <IntegrationCard
            info={Mint}
            data={data.mint}
            settings={{
              type: "tooltip",
              info: {
                Key: data?.mint?.settings?.key,
                "Signing Key": data?.mint?.settings?.signing_key,
                URL: data?.mint?.settings?.url,
              },
            }}
            {...cardProps}
          />
          <IntegrationCard
            info={CRS}
            data={data.crs}
            settings={{
              type: "tooltip",
              info: {
                Username: data?.crs?.settings?.username,
                Password: "************",
                URL: data?.crs?.settings?.url,
              },
            }}
            {...cardProps}
          />
          <IntegrationCard
            info={BlueRock}
            data={data.bluerock}
            settings={{
              type: "tooltip",
              info: {
                Username: data?.bluerock?.settings?.username,
                Password: data?.bluerock?.settings?.password,
              },
            }}
            {...cardProps}
          />
        </div>
      </div>
    </div>
  );
}

export default Integrations;
