import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { getTrigger } from "App/Admin/_graphql/queries/trigger";
import { saveTrigger } from "App/Admin/_graphql/mutations/trigger";
import { getErrors } from "_helpers/api";
import {
  IconBolt,
  IconCircleCheck,
  IconCircleCheckFilled,
  IconPlus,
} from "@tabler/icons-react";
import SelectTrigger from "./_components/SelectTrigger/SelectTrigger";
import SelectActions from "./_components/SelectActions/SelectActions";
import { FormInput, Flex, Loading, Tag } from "_styleguide";
import { Collapse } from "antd";
import {
  triggerFriendly,
  requiresMultiple,
} from "./_components/SelectTrigger/_types";
import {
  friendlyAction,
  requiresCustom,
} from "./_components/SelectActions/_types";
import { customMultiple } from "./_components/SelectTrigger/_custom";
import { useTags } from "_contexts/useTags";
import "./ManageTrigger.scss";

function ManageTrigger(props) {
  const client = useApolloClient();
  const { tags, reloadTags } = useTags();
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState();

  const isEditing = props.triggerId !== "new";

  const fetchTrigger = async () => {
    if (isEditing) {
      const queryData = await client.query({
        variables: { trigger_id: props?.triggerId },
        query: getTrigger,
      });
      setLoading(false);
      setForm(queryData?.data?.getTrigger);
    } else {
      setLoading(false);
      setForm({
        name: undefined,
        is_active: undefined,
        tag: undefined,
        trigger_key: undefined,
        trigger_ref_id: undefined,
        trigger_multiple: [],
        actions: [
          {
            action_key: undefined,
            action_ref_id: undefined,
            action_options: [],
          },
        ],
      });
    }
  };

  // Submit question form
  const saveForm = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          trigger_id: props.triggerId !== "new" ? props.triggerId : null,
          trigger: form,
        },
        mutation: saveTrigger,
      });
      message.success(
        `Trigger successfully ${isEditing ? "saved" : "created"}`
      );
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(
        getErrors(err) ||
          `Error ${isEditing ? "saving" : "creating"} trigger, try again`
      );
      setSaving(false);
    }
    setSaving(false);
  };

  const updateForm = (field, value) => {
    const editedform = { ...form };
    editedform[field] = value;
    setForm(editedform);
  };

  const updateAction = (value, index) => {
    const editedform = { ...form };
    editedform.actions[index] = value;
    setForm(editedform);
  };

  const updateOptions = (value, index, field) => {
    const editedform = { ...form };
    const editedOptions = [
      ...(editedform.actions[index]?.action_options || []),
    ];
    const exists = editedOptions?.findIndex((p) => p.name === field);
    if (exists >= 0) {
      editedOptions[exists] = {
        name: field,
        value: value,
      };
    } else {
      editedOptions.push({
        name: field,
        value: value,
      });
    }
    editedform.actions[index].action_options = editedOptions;
    setForm(editedform);
  };

  const addAction = () => {
    const editedform = { ...form };
    form.actions.push({
      action_key: undefined,
      action_ref_id: undefined,
    });
    setForm(editedform);
  };

  const updateObject = (values) => {
    const editedform = { ...form, ...values };
    setForm(editedform);
  };

  useEffect(() => {
    fetchTrigger();
    reloadTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nameGood = form?.name?.length > 0 ? true : false;

  const needsMultiple = requiresMultiple.includes(form?.trigger_key);
  const hasMultiple = !needsMultiple || form?.trigger_multiple?.length > 0;
  const triggerGood =
    form?.trigger_key?.length > 0 && hasMultiple ? true : false;
  let actionsGood = true;
  const multipleActions = form?.actions?.length > 1 ? true : false;
  const multipleMap = customMultiple[form?.trigger_key];

  let multipleSelected = [];
  if (needsMultiple && triggerGood && multipleMap) {
    form?.trigger_multiple?.forEach((m) => {
      multipleSelected.push(multipleMap[m]);
    });
  }

  const options = {
    tags: tags || [],
    workflows: props.workflows || [],
    users: props.users || [],
    teams: props.teams || [],
    templates: props.templates || [],
  };

  let items = [];
  if (form) {
    const actionsList = form?.actions?.map((a, i) => {
      const actionData = form?.actions?.[i];
      console.log(actionData);
      let isComplete = true;
      const customOptions = actionData?.action_options || [];
      requiresCustom[actionData?.action_key || "X"]?.forEach((c) => {
        const hasFilled = customOptions?.find(
          (co) => c === co.name && co.value
        );
        if (!hasFilled) isComplete = false;
      });

      const actionGood =
        actionData?.action_key?.length > 0 && isComplete ? true : false;
      if (!actionGood) actionsGood = false;
      return {
        key: `action-${i}`,
        label: (
          <Flex className="cllps-title">
            {actionGood ? <IconCircleCheckFilled /> : <IconCircleCheck />}
            <h4>{multipleActions ? `Action #${i + 1}:` : `Action:`}</h4>
            {actionGood
              ? friendlyAction(
                  a?.action_key,
                  a?.action_ref_id,
                  options,
                  a?.action_options
                )
              : null}
          </Flex>
        ),
        children: (
          <SelectActions
            updateForm={(value) => updateAction(value, i)}
            updateOptions={(field, value) => updateOptions(value, i, field)}
            actionIndex={i}
            actionData={actionData}
            options={options}
          />
        ),
      };
    });

    items = [
      {
        key: "name",
        label: (
          <Flex className="cllps-title">
            {nameGood ? <IconCircleCheckFilled /> : <IconCircleCheck />}
            <h4>Name:</h4>
            {nameGood ? <Tag>{form?.name}</Tag> : null}
          </Flex>
        ),
        children: (
          <FormInput
            id="name"
            value={form?.name}
            disabled={saving}
            onChange={(e) => updateForm("name", e.target.value)}
            placeholder="e.g. Add tag to new leads"
            size="small"
          />
        ),
      },
      {
        key: "trigger",
        label: (
          <Flex className="cllps-title">
            {triggerGood ? <IconCircleCheckFilled /> : <IconCircleCheck />}
            <h4>Trigger:</h4>
            {triggerGood ? (
              <Tag type="warning">
                {triggerFriendly[form?.trigger_key]}{" "}
                {multipleSelected?.length > 0
                  ? `- ${multipleSelected?.length} option(s)`
                  : ``}
              </Tag>
            ) : null}
          </Flex>
        ),
        children: (
          <SelectTrigger
            updateForm={updateObject}
            updateMultiple={updateForm}
            form={form}
          />
        ),
      },
      ...actionsList,
    ];
  }
  const isGood = nameGood && triggerGood && actionsGood ? true : false;

  return (
    <Modal
      wrapClassName="manage-trigger-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={loading ? true : false}
      width={550}
    >
      {loading ? (
        <Modal.Body>
          <Loading />
        </Modal.Body>
      ) : (
        <>
          <div className="pp-modal-header">
            <div>
              <IconBolt />
            </div>
            <h3>{isEditing ? "Manage" : "Create"} Trigger</h3>
          </div>

          <Modal.Body>
            {form ? (
              <>
                <Collapse
                  items={items}
                  defaultActiveKey={["name"]}
                  accordion
                  expandIconPosition="end"
                />
                <Button
                  type="secondary"
                  size="small"
                  onClick={addAction}
                  className="boxed"
                >
                  <IconPlus /> Add Action
                </Button>
              </>
            ) : (
              <Loading />
            )}
          </Modal.Body>

          <Modal.Actions>
            <Button
              disabled={saving || loading}
              block
              type="secondary"
              onClick={() => setVisible(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={saveForm}
              loading={saving}
              block
              type="primary"
              disabled={loading || !isGood}
            >
              {isEditing ? "Save" : "Create"} Trigger
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
}

export default ManageTrigger;
