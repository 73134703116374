import { gql } from "@apollo/client";

export const savePostgrid = gql`
  mutation savePostgrid($api_key: String!) {
    savePostgrid(api_key: $api_key)
  }
`;

export const setupWebhookIntegration = gql`
  mutation setupWebhookIntegration($type: String!, $settings: String) {
    setupWebhookIntegration(type: $type, settings: $settings)
  }
`;

export const setupBlueRock = gql`
  mutation setupBlueRock($username: String!, $password: String!) {
    setupBlueRock(username: $username, password: $password)
  }
`;
