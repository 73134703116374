import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { IconWebhook } from "@tabler/icons-react";
import IntegrationCard from "../Integrations/_shared/IntegrationCard/IntegrationCard";
import { getIntegrations } from "App/Admin/_graphql/queries/integration";
import { useIntegrations } from "_contexts/useIntegrations";
import {
  disconnectIntegration,
  toggleIntegration,
} from "App/Admin/_graphql/mutations/integration";
import { getErrors } from "_helpers/api";
import { WebhookLeads, WebhookLeadsForth } from "../Integrations/_data";
import { Loading } from "_styleguide";
import { Modal, message } from "@centrate-io/barn";
import "../Integrations/Integrations.scss";

function Webhooks(props) {
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [integrations, setIntegrations] = useState([]);
  const { reloadIntegrations } = useIntegrations();

  // Load Payments
  const fetchIntegrations = async () => {
    const queryData = await client.query({
      query: getIntegrations,
    });
    setIntegrations(queryData?.data?.getIntegrations || []);
    reloadIntegrations();
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  // Disconnect
  const removeIntegration = async (integration_id) => {
    Modal.confirm({
      title: "Are you sure you want remove disconnect this webhook?",
      okText: "Disconnect",
      content:
        "Warning: This will remove the webhook and all associated data.  You will no longer be able to use this webhook on the platform until you re-connect.",
      onOk: async () => {
        try {
          await client.mutate({
            variables: {
              integration_id: integration_id,
            },
            mutation: disconnectIntegration,
          });
          message.success(`Webhook successfully disconnected`);
          fetchIntegrations();
        } catch (err) {
          message.error(
            getErrors(err) || "Error disconnecting webhook, try again"
          );
        }
      },
    });
  };

  // Toggle
  const toggleActive = async (integration_id, active) => {
    Modal.confirm({
      title: `Are you sure you want ${
        active ? "de-activate" : "activate"
      } this webhook?`,
      okText: active ? "De-activate" : "Activate",
      content: active
        ? "Warning: De-activating this webhook will not allow you to use it on the platform.  All of your data will remain until disconnected"
        : "Activating this webhook will allow you to use it on the platform immediately",
      onOk: async () => {
        try {
          await client.mutate({
            variables: {
              integration_id: integration_id,
            },
            mutation: toggleIntegration,
          });
          message.success(
            `Webhook has been ${active ? "de-activated" : "activated"}`
          );
          fetchIntegrations();
        } catch (err) {
          message.error(getErrors(err) || "Error toggling webhook, try again");
        }
      },
    });
  };

  useEffect(() => {
    props?.setCurrentCount(0);
    props.setCrumbs({
      title: "Webhooks",
      icon: <IconWebhook />,
      description:
        "Improve your experience by connecting to our available API endpoints",
    });
    fetchIntegrations();
  }, []);

  const integrationData = (intModel) => {
    const type = intModel.type;
    if (intModel.company_only) {
      return (
        integrations.find((i) => {
          return i.type === type;
        }) || null
      );
    } else {
      return (
        integrations.find((i) => {
          return i.type === type && i.user_id === props.user?.id;
        }) || null
      );
    }
  };

  const cardProps = {
    reload: fetchIntegrations,
    toggleActive,
    removeIntegration,
    ...props,
  };

  const data = {
    "webhook-leads": integrationData(WebhookLeads),
    "webhook-leads-forth": integrationData(WebhookLeadsForth),
  };

  return loading ? (
    <div className="settings-integrations">
      <Loading />
    </div>
  ) : (
    <div className="settings-integrations">
      <div className="int-section">
        <div className="int-options">
          <IntegrationCard
            info={WebhookLeads}
            data={data["webhook-leads"]}
            settings={{
              type: "modal",
              info: {
                API_KEY: data?.["webhook-leads"]?.settings?.api_key,
                company_name: data?.["webhook-leads"]?.settings?.company_name,
              },
            }}
            {...cardProps}
          />
          <IntegrationCard
            info={WebhookLeadsForth}
            data={data["webhook-leads-forth"]}
            settings={{
              type: "tooltip",
              info: {
                URL: data?.["webhook-leads-forth"]?.settings?.url,
                TYPE: "POST",
              },
            }}
            {...cardProps}
          />
        </div>
      </div>
    </div>
  );
}

export default Webhooks;
