export const triggerTypes = [
  {
    name: "Lead",
    value: "LEAD",
    next: {
      name: "Event",
      options: [
        {
          name: "Status Change",
          value: "STATUS",
          next: {
            name: "Changed to",
            options: [
              {
                name: "Created",
                value: "CREATED",
              },
              {
                name: "Imported",
                value: "IMPORTED",
              },
              {
                name: "Webhook Import",
                value: "WEBHOOK",
              },
              {
                name: "Subscribed",
                value: "CUSTOMER",
              },
            ],
          },
        },
        {
          name: "Apply (Self-Enroll)",
          value: "APPLY",
          next: {
            name: "Changed to",
            options: [
              {
                name: "Created",
                value: "CREATED",
              },
              {
                name: "Subscribed",
                value: "CUSTOMER",
              },
            ],
          },
        },
      ],
    },
  },
  {
    name: "Customer",
    value: "CUSTOMER",
    next: {
      name: "Event",
      options: [
        {
          name: "Status Change",
          value: "STATUS",
          next: {
            name: "Changed to",
            options: [
              {
                name: "Paused",
                value: "PAUSED",
              },
              {
                name: "Cancelled",
                value: "CANCELLED",
              },
            ],
          },
        },
      ],
    },
  },
  {
    name: "Payment",
    value: "PAYMENT",
    next: {
      name: "Event",
      options: [
        {
          name: "Failed",
          value: "FAILED",
          next: {
            name: "Reason",
            multiple: true,
            options: [
              {
                name: "Insufficient Funds",
                value: "NSF",
              },
              {
                name: "Account Closed",
                value: "CLOSED",
              },
              {
                name: "Account Invalid",
                value: "INVALID",
              },
              {
                name: "Account Frozen",
                value: "FROZEN",
              },
              {
                name: "Payment Stopped",
                value: "STOPPED",
              },
              {
                name: "Unauthorized",
                value: "UNAUTHORIZED",
              },
            ],
          },
        },
      ],
    },
  },
  {
    name: "Document",
    value: "DOCUMENT",
    next: {
      name: "Event",
      options: [
        {
          name: "Added",
          value: "ADDED",
        },
        {
          name: "Removed",
          value: "REMOVED",
        },
      ],
    },
  },
  {
    name: "Mail",
    value: "MAIL",
    next: {
      name: "Event",
      options: [
        {
          name: "Printing",
          value: "PRINTING",
        },
        {
          name: "Processed",
          value: "PROCESSED",
        },
        {
          name: "Cancelled",
          value: "CANCELLED",
        },
        {
          name: "Sent",
          value: "SENT",
        },
      ],
    },
  },
];

export const requiresMultiple = ["PAYMENT_FAILED"];

export const triggerFriendly = {
  LEAD_STATUS_CREATED: "Lead - Created",
  LEAD_STATUS_IMPORTED: "Lead - Imported",
  LEAD_STATUS_WEBHOOK: "Lead - Webhook Import",
  LEAD_STATUS_CUSTOMER: "Lead - Subscribed",
  LEAD_APPLY_CREATED: "Apply - Created",
  LEAD_APPLY_CUSTOMER: "Apply - Subscribed",
  CUSTOMER_STATUS_PAUSED: "Customer - Paused",
  CUSTOMER_STATUS_CANCELLED: "Customer - Cancelled",
  PAYMENT_FAILED: "Payment Failed",
  DOCUMENT_ADDED: "Document - Added",
  DOCUMENT_REMOVED: "Document - Removed",
  MAIL_PRINTING: "Mail - Printing",
  MAIL_PROCESSED: "Mail - Processed",
  MAIL_CANCELLED: "Mail - Cancelled",
  MAIL_SENT: "Mail - Sent",
  // PAYMENT_FAILED_NSF: "Payment Failed - NSF",
  // PAYMENT_FAILED_CLOSED: "Payment Failed - Account Closed",
  // PAYMENT_FAILED_INVALID: "Payment Failed - Invalid Account",
  // PAYMENT_FAILED_FROZEN: "Payment Failed - Account Frozen",
  // PAYMENT_FAILED_STOPPED: "Payment Failed - Payment Stopped",
  // PAYMENT_FAILED_UNAUTHORIZED: "Payment Failed - Unauthorized",
};

export const allTriggers = Object.keys(triggerFriendly);
