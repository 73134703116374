import React, { useState } from "react";
import {
  IconGripVertical,
  IconInfoCircle,
  IconEyeOff,
  IconEye,
} from "@tabler/icons-react";
import { Button, Tooltip } from "@centrate-io/barn";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { pageMap } from "../../_helpers";
import { TableActions } from "_styleguide";
import "./Flow.scss";

function Flow(props) {
  const { settings, u, previewPage, setPreviewPage, pageValidation } = props;
  const [isDragging, setIsDragging] = useState(false);

  const hiddenPages = settings.order.filter(
    (pageId) => !settings.settings[pageId]?.visible
  );
  const visiblePages = settings.order.filter(
    (pageId) => settings.settings[pageId]?.visible
  );

  return (
    <>
      <div className="settings-self-enrollment-flow">
        <DragDropContext
          onDragStart={() => setIsDragging(true)}
          onDragEnd={async (result) => {
            setIsDragging(false);
            if (!result.destination) return;
            const items = Array.from(settings.order);
            const sourceIndex = items.findIndex(
              (item) => item === result.draggableId
            );
            const [reorderedItem] = items.splice(sourceIndex, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            u("order", items);
          }}
        >
          <Droppable droppableId="pages">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {visiblePages?.map((pageId, index) => (
                  <Draggable key={pageId} draggableId={pageId} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`flow-page-item ${
                          previewPage === pageId ? "active" : ""
                        } ${pageValidation[pageId] ? "" : "invalid"}`}
                        onClick={() => setPreviewPage(pageId)}
                      >
                        <div
                          className="drag-handle"
                          {...provided.dragHandleProps}
                        >
                          <IconGripVertical />
                        </div>
                        <div className="page-name">
                          <div className="page-number">{index + 1}.</div>
                          <div className="page-title">
                            <h5>
                              {pageMap[pageId]?.title ||
                                pageId.charAt(0).toUpperCase() +
                                  pageId.slice(1).replace(/_/g, " ")}
                            </h5>
                            {pageMap[pageId]?.required?.length > 0 ? (
                              <p>
                                <Tooltip title="Required pages must be enabled and appear before this page in the flow">
                                  <IconInfoCircle />
                                </Tooltip>
                                Required:{" "}
                                {pageMap[pageId]?.required
                                  ?.map(
                                    (r) =>
                                      pageMap[r]?.title ||
                                      r.charAt(0).toUpperCase() +
                                        r.slice(1).replace(/_/g, " ")
                                  )
                                  .join(", ")}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        {!isDragging && (
                          <div className="page-actions">
                            <Link to={`/settings/self-enrollment/${pageId}`}>
                              <Button
                                type="blanco"
                                size="small"
                                className="boxed"
                              >
                                Edit
                              </Button>
                            </Link>
                            <TableActions
                              items={[
                                {
                                  label: (
                                    <span>
                                      <IconEyeOff /> Hide Page
                                    </span>
                                  ),
                                  onClick: () =>
                                    props.us(pageId, "visible", false),
                                },
                              ]}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {hiddenPages?.length > 0 ? (
        <>
          <div className="hidden-pages">
            <IconEyeOff />
            <h4>Disabled Page(s)</h4>
          </div>
          <div className="settings-self-enrollment-flow">
            {hiddenPages?.map((pageId) => (
              <div key={pageId} className={`flow-page-item`}>
                <div className="page-name">
                  <div className="page-title">
                    <h5>{pageMap[pageId]?.title}</h5>
                  </div>
                </div>
                <div className="page-actions">
                  <Link to={`/settings/self-enrollment/${pageId}`}>
                    <Button type="blanco" size="small" className="boxed">
                      Edit
                    </Button>
                  </Link>
                  <TableActions
                    items={[
                      {
                        label: (
                          <span>
                            <IconEye /> Show Page
                          </span>
                        ),
                        onClick: () => props.us(pageId, "visible", true),
                      },
                    ]}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : null}
    </>
  );
}

export default Flow;
