import React, { useState, useEffect } from "react";
import { Table, Empty, Loading, FilterTags } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { getColumns } from "./_data/Columns";
import { Button, message, Modal } from "@centrate-io/barn";
import ManageTrigger from "./_modals/ManageTrigger/ManageTrigger";
import { IconPlus, IconBolt, IconListSearch } from "@tabler/icons-react";
import { getErrors } from "_helpers/api";
import { getTriggers } from "App/Admin/_graphql/queries/trigger";
import { myUsers } from "App/Admin/_graphql/queries/user";
import { getTeams } from "App/Admin/_graphql/queries/team";
import { getTaskTemplates } from "App/Admin/_graphql/queries/task";
import {
  removeTrigger,
  toggleTrigger,
} from "App/Admin/_graphql/mutations/trigger";
import { getWorkflows } from "App/Admin/_graphql/queries/workflow";
import { useTags } from "_contexts/useTags";
import { useNavigate } from "react-router-dom";
import "./Triggers.scss";

function Triggers(props) {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { tags } = useTags();
  const [manageModal, setManageModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [triggers, setTriggers] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [filterTag, setFilterTag] = useState([]);

  // Load Payments
  const fetchWorkflows = async () => {
    const queryData = await client.query({
      query: getWorkflows,
    });
    setWorkflows(queryData?.data?.getWorkflows || []);
  };
  const fetchTriggers = async () => {
    const queryData = await client.query({
      query: getTriggers,
    });
    setLoading(false);
    setTriggers(queryData?.data?.getTriggers);
  };
  const fetchUsers = async () => {
    const queryData = await client.query({
      query: myUsers,
    });
    setUsers(queryData?.data?.myUsers || []);
  };
  const fetchTeams = async () => {
    const queryData = await client.query({
      query: getTeams,
    });
    setTeams(queryData?.data?.getTeams || []);
  };
  const fetchTemplates = async () => {
    const queryData = await client.query({
      query: getTaskTemplates,
    });
    setTemplates(queryData?.data?.getTaskTemplates || []);
  };

  // Remove Bank Account
  const deleteTrigger = async (triggerId) => {
    Modal.confirm({
      title: "Are you sure you want remove this Trigger?",
      okText: "Remove Trigger",
      content: (
        <p>
          <b style={{ fontWeight: 600, color: "#000" }}>
            Warning: This cannot be undone!
          </b>
        </p>
      ),
      onOk: async () => {
        try {
          await client.mutate({
            variables: {
              trigger_id: triggerId,
            },
            mutation: removeTrigger,
          });
          message.success(`Trigger successfully removed`);
          fetchTriggers();
        } catch (err) {
          console.log(err);
          message.error(getErrors(err) || "Error removing trigger, try again");
        }
      },
    });
  };

  // Toggle
  const toggleActive = async (trigger_id, active) => {
    Modal.confirm({
      title: `Are you sure you want ${
        active ? "de-activate" : "activate"
      } this trigger?`,
      okText: active ? "De-activate" : "Activate",
      content: active
        ? "Warning: De-activating this trigger will pause all actions taken on the selected trigger immediately."
        : "Activating this trigger will invoke the action immediately once active",
      onOk: async () => {
        try {
          await client.mutate({
            variables: {
              trigger_id: trigger_id,
            },
            mutation: toggleTrigger,
          });
          message.success(
            `Trigger has been ${active ? "de-activated" : "activated"}`
          );
          fetchTriggers();
        } catch (err) {
          console.log(err);
          message.error(getErrors(err) || "Error toggling trigger, try again");
        }
      },
    });
  };

  useEffect(() => {
    props?.setCurrentCount(0);
    props.setCrumbs({
      title: "Triggers",
      icon: <IconBolt />,
      description: "Create and manage your custom triggers and automations.",
    });
    fetchWorkflows();
    fetchTriggers();
    fetchUsers();
    fetchTeams();
    fetchTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let tableData = triggers || [];
  tableData = tableData?.filter((d) => {
    if (filterTag?.length <= 0) return true;
    const commonElements = d.tags?.filter((item) => filterTag?.includes(item));
    return commonElements?.length > 0;
  });
  const options = { tags, workflows, users, teams, templates };
  const columns = getColumns(
    setManageModal,
    deleteTrigger,
    props.readOnly,
    options,
    toggleActive,
    tags
  );
  const isEmpty = (triggers || [])?.length <= 0;

  return loading ? (
    <div className="settings-integrations">
      <Loading />
    </div>
  ) : (
    <>
      {manageModal ? (
        <ManageTrigger
          triggerId={manageModal}
          reload={fetchTriggers}
          users={users}
          teams={teams}
          templates={templates}
          workflows={workflows}
          removeModal={() => setManageModal(false)}
        />
      ) : null}
      {isEmpty ? (
        <Empty
          title="Create Your First Trigger"
          description="Triggers can help you automate changes in your data across PayPath..."
          action="Create Trigger"
          actionIcon={<IconPlus />}
          onClick={() => setManageModal("new")}
        />
      ) : (
        <div className="settings-triggers">
          <div className="settings-search">
            <div className="ms-filters">
              <FilterTags
                value={filterTag}
                onChange={(val) => setFilterTag(val)}
              />
            </div>
            <div className="ms-actions">
              <Button
                type="blanco"
                size="small"
                onClick={() => navigate("/logs")}
              >
                <IconListSearch />
                Logs
              </Button>
              {!props.readOnly ? (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => setManageModal("new")}
                >
                  <IconPlus />
                  Create Trigger
                </Button>
              ) : null}
            </div>
          </div>
          <Table
            columns={columns}
            loading={loading}
            pagination={{ pageSize: 10, showSizeChanger: false }}
            rowKey={(record) => record.id}
            dataSource={tableData}
            showSorterTooltip={false}
            scroll={{
              y: "calc(100vh - 190px)",
              x: "max-content",
            }}
            locale={{
              emptyText: loading ? "Loading triggers..." : "No triggers found",
            }}
          />
        </div>
      )}
    </>
  );
}

export default Triggers;
