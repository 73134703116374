import { useState, useEffect } from "react";
import { Button, message, Modal } from "@centrate-io/barn";
import { getErrors } from "_helpers/api";
import { IconWebhook } from "@tabler/icons-react";
import { InputLabel, Flex, AlertBox, Loading, Select } from "_styleguide";
import { CallsClient } from "_graphql/clients/CallsClient";
import { useApolloClient } from "@apollo/client";
import { setupWebhookIntegration } from "_graphql/mutations/integration";
import { getMyCompanies } from "_graphql/queries/company";
import "./IncomingLeadSetup.scss";

function IncomingLeadSetup(props) {
  const callsClient = CallsClient;
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [company, setCompany] = useState(undefined);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);

  const findCompanies = async () => {
    const res = await client.query({
      query: getMyCompanies,
    });
    setCompanies(res.data.getMyCompanies);
    setLoading(false);
  };

  useEffect(() => {
    findCompanies();
  }, []);

  const setupWebhook = async () => {
    setSaving(true);
    try {
      await callsClient.mutate({
        variables: {
          type: "webhook-leads",
          settings: JSON.stringify({
            company_id: company?.id,
            company_name: company?.name,
            user_id: company?.user?.id,
          }),
        },
        mutation: setupWebhookIntegration,
      });
      message.success(`Import lead webhook integration setup successfully`);
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(
        getErrors(err) || "Error setting up integration, try again"
      );
      setSaving(false);
    }
  };

  return (
    <Modal
      wrapClassName="incoming-lead-setup-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={600}
    >
      <div className="pp-modal-header">
        <div>
          <IconWebhook />
        </div>
        <h3>Setup Import Lead Webhook</h3>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <Modal.Body>
          <Flex vertical={true}>
            <InputLabel htmlFor="company">
              Select Company for Attribution *
            </InputLabel>
            <Select
              id="company"
              value={company?.id || undefined}
              size="extra-medium"
              popupClassName="pp-select-dropdown"
              onChange={(val) =>
                setCompany(companies.find((c) => c.id === val))
              }
            >
              {companies.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Flex>
          <AlertBox type="info">
            All leads will be attributed to the company you select.
          </AlertBox>
        </Modal.Body>
      )}

      <Modal.Actions>
        <Button block type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button
          block
          type="primary"
          disabled={!company}
          onClick={setupWebhook}
          loading={saving}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default IncomingLeadSetup;
