import React, { useState, useEffect } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Applications from "./Applications/Applications";
import Subscriptions from "./Subscriptions/Subscriptions";
import Application from "./Application/Application";
import Companies from "./Companies/Companies";
import Users from "./Users/Users";
import Payments from "./Payments/Payments";
import Tasks from "./Tasks/Tasks";
import Analytics from "./Analytics/Analytics";
import Playground from "./Playground/Playground";
import AddressBook from "./AddressBook/AddressBook";
import Wizard from "./Wizard/Wizard";
import Logs from "./Logs/Logs";
import Company from "./_components/Company/Company";
import Settings from "./Settings/Settings";
import MyTasks from "./_components/MyTasks/MyTasks";
import Bar from "./_components/Bar/Bar";
import { Button } from "@centrate-io/barn";
import {
  IconFiles,
  IconReceipt2,
  IconSettings,
  IconCash,
  IconPlus,
  IconSitemap,
  IconChartHistogram,
  IconBeach,
  IconAddressBook,
  IconClipboardCheck,
} from "@tabler/icons-react";
import { Header, Text } from "@centrate-io/barn";
import { Layout, Menu } from "antd";
import { getSelectedKeys } from "_helpers/routing";
import { useApolloClient } from "@apollo/client";
import { getMyTasksCount } from "App/Admin/_graphql/queries/task";
import { numberWithCommas } from "_assets/js/helpers";
import { masqueradeBack } from "App/Admin/_graphql/mutations/user";
import { TagsProvider } from "_contexts/useTags";
import "./Admin.scss";

const { Content, Sider } = Layout;

const getItem = (label, key, icon, children) => ({
  key,
  icon,
  children,
  label,
});
const items = [
  getItem(<Link to="/">Leads</Link>, "leads", <IconFiles />),
  getItem(
    <Link to="/customers">Customers</Link>,
    "customers",
    <IconReceipt2 />
  ),
  // getItem(<Link to="/analytics">Analytics</Link>, "4", <IconChartAreaLine />),
];

function Admin(props) {
  const client = useApolloClient();
  const [collapsed, setCollapsed] = useState(false);
  const [currentCount, setCurrentCount] = useState();
  const [crumbs, setCrumbs] = useState();
  const [tasksOpen, setTasksOpen] = useState(false);
  const [reset, setReset] = useState(true);
  const [taskCount, setTaskCount] = useState(false);
  const [isModifying, setIsModifying] = useState(false);
  const [templates, setTemplates] = useState({
    data: [],
    reload: null,
  });

  let location = useLocation();
  const path = location?.pathname;
  const tablePaths = ["/"];
  const isWizard = path.includes("wizard");
  const isWorkflows = path.includes("workflows");
  const isEditWorkflow = path.includes("workflows/");
  const isAnalytics = path.includes("analytics");
  const isSettings = path.includes("settings");
  const isSelfEnrollment = path.includes("self-enrollment");
  const isCustomer = path.includes("/customer/");
  const unmasq = async () => {
    const queryData = await client.mutate({
      mutation: masqueradeBack,
    });
    const token = queryData?.data?.masqueradeBack;
    if (token && props.login) {
      props.login(token);
      fetchTaskCount();
    }
  };

  const fetchTaskCount = async () => {
    const queryData = await client.query({
      query: getMyTasksCount,
    });
    let todoTasks = queryData?.data?.getMyTasks?.todo || [];
    setTaskCount(todoTasks?.length || 0);
  };

  useEffect(() => {
    fetchTaskCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const readOnly = props.user?.company?.read_only ? true : false;

  const globalProps = {
    setCurrentCount,
    setCrumbs,
    user: props.user,
    templates,
    setTemplates,
    setIsModifying,
    readOnly,
  };

  let userItems = [
    { type: "divider" },
    // getItem(<Link to="/users">Users</Link>, "users", <IconUsers />),
  ];

  let companies = [];
  let payments = [];
  const canManageCompanies =
    props?.user?.role === "admin" ||
    props?.user?.role === "super" ||
    props?.user?.company?.is_owner === "owner";
  const isSuperAdmin = props?.user?.role === "super";
  if (canManageCompanies) {
    payments = [
      getItem(<Link to="/payments">Payments</Link>, "payments", <IconCash />),
      getItem(<Link to="/tasks">Tasks</Link>, "tasks", <IconClipboardCheck />),
    ];
  }
  let addressBook = [
    getItem(
      <Link to="/address-book">Address Book</Link>,
      "address-book",
      <IconAddressBook />
    ),
  ];
  // addressBook = [];
  if (isSuperAdmin) {
    payments.push(
      getItem(
        <Link to="/analytics">Analytics</Link>,
        "analytics",
        <IconChartHistogram />
      )
    );
  }

  const canMangeSettings =
    props?.user?.role !== "user" && props?.user?.role !== "removed";

  if (props?.user?.role === "user") {
    userItems = [];
    companies = [];
  }

  let settings = [];
  if (canMangeSettings) {
    settings = [
      getItem(
        <Link to="/settings">Settings</Link>,
        "settings",
        <IconSettings />
      ),
    ];
  }

  let play = [];
  if (isSuperAdmin && process.env.NODE_ENV === "development") {
    play = [
      { type: "divider" },
      getItem(
        <Link to="/playground">Playground</Link>,
        "playground",
        <IconBeach />
      ),
    ];
  }

  const showMyTasks = () => {
    setReset(true);
    setTimeout(() => {
      setReset(false);
      setTasksOpen(true);
    }, 1);
  };
  let tasks = [
    getItem(
      <div onClick={tasksOpen ? () => setTasksOpen(false) : showMyTasks}>
        My Tasks
        {taskCount > 0 ? (
          <span className="admin-task-count">
            {numberWithCommas(taskCount)}
          </span>
        ) : null}
      </div>,
      "my-tasks",
      <IconSitemap />
    ),
    { type: "divider" },
  ];
  if (readOnly) tasks = [];

  const menuKeys = getSelectedKeys(location?.pathname, tasksOpen, isModifying);

  const isMasq = props?.user?.masq ? true : false;

  return (
    <TagsProvider client={client}>
      <Layout
        className={isMasq ? "layout-admin masq" : "layout-admin"}
        hasSider
      >
        {!reset ? (
          <MyTasks
            onClose={() => setTasksOpen(false)}
            open={tasksOpen}
            task={tasksOpen}
            collapsed={collapsed}
            setTaskCount={setTaskCount}
          />
        ) : null}
        <Sider
          width={240}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Company
            login={props.login}
            user={props?.user}
            collapsed={collapsed}
          />
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={menuKeys}
            items={[
              ...tasks,
              ...items,
              ...payments,
              ...addressBook,
              ...userItems,
              ...companies,
              ...settings,
              ...play,
            ]}
          />

          {!readOnly ? (
            <Link to="/wizard/new">
              <Button className="wizard-btn">
                <IconPlus /> New Application
              </Button>
            </Link>
          ) : null}
          {isMasq ? (
            <div id="masq">
              <b>Warning:</b> You are logged in as a different user -{" "}
              <span onClick={unmasq}>Go Back</span>
            </div>
          ) : null}
          {readOnly ? (
            <div id="masq">
              <b>Warning:</b> This organization is in view-only mode. Reach out
              to restore functionality.
            </div>
          ) : null}
        </Sider>
        <Layout
          className={`site-layout ${collapsed ? "collapsed" : ""} ${
            isSettings && "layout-settings"
          }`}
        >
          {!readOnly ? (
            <Bar
              showMyTasks={showMyTasks}
              fetchTaskCount={fetchTaskCount}
              {...props}
              {...globalProps}
            />
          ) : null}

          <Content
            className={`site-content ${tablePaths.includes(path) && "sheet"} ${
              isWizard && "wizard"
            } ${isWorkflows && "workflows"} ${isSettings && "settings"} ${
              isAnalytics && "analytics"
            } ${isEditWorkflow && "edit-workflow"} ${
              isSelfEnrollment && "self-enrollment"
            }`}
          >
            <div className="admin-header">
              {crumbs ? (
                <div className="crumbs">
                  <Header type="display" size="5">
                    {crumbs?.icon}
                    {crumbs?.title}
                    <span className="current-count">
                      {currentCount && currentCount > 0
                        ? ` (${currentCount})`
                        : null}
                    </span>
                    {crumbs?.tag}
                  </Header>
                  <Text type="helper">{crumbs?.description}</Text>
                </div>
              ) : null}
            </div>
            <div className={`admin-body ${isCustomer ? "admin-customer" : ""}`}>
              <Routes>
                <Route
                  exact
                  path="/"
                  id="leads"
                  element={<Applications {...props} {...globalProps} />}
                />
                <Route
                  exact
                  path="/customers/:tab?"
                  id="customers"
                  element={<Subscriptions {...props} {...globalProps} />}
                />

                <Route
                  exact
                  path="/customer/:id/:tab?"
                  id="customer"
                  element={<Application {...props} {...globalProps} />}
                />
                {canManageCompanies && (
                  <Route
                    exact
                    path="/payments"
                    id="payments"
                    element={<Payments {...props} {...globalProps} />}
                  />
                )}
                {canManageCompanies && (
                  <Route
                    exact
                    path="/tasks"
                    id="tasks"
                    element={<Tasks {...props} {...globalProps} />}
                  />
                )}
                <Route
                  exact
                  path="/address-book"
                  id="address-book"
                  element={<AddressBook {...props} {...globalProps} />}
                />
                <Route
                  exact
                  path="/logs"
                  id="logs"
                  element={<Logs {...props} {...globalProps} />}
                />

                {canManageCompanies && (
                  <Route
                    exact
                    path="/analytics/:tab?"
                    id="analytics"
                    element={<Analytics {...props} {...globalProps} />}
                  />
                )}
                {canManageCompanies && (
                  <Route
                    exact
                    path="/playground"
                    id="playground"
                    element={<Playground {...props} {...globalProps} />}
                  />
                )}
                {canManageCompanies && (
                  <Route
                    exact
                    path="/companies"
                    id="companies"
                    element={<Companies {...props} {...globalProps} />}
                  />
                )}
                <Route
                  exact
                  path="/users"
                  id="users"
                  element={<Users {...props} {...globalProps} />}
                />
                <Route
                  exact
                  path="/settings/:tab?/:itemId?"
                  id="settings"
                  element={
                    <Settings
                      {...props}
                      {...globalProps}
                      fetchTaskCount={fetchTaskCount}
                      canManageCompanies={canManageCompanies}
                    />
                  }
                />
                <Route
                  exact
                  path="/wizard/:applicationId/:tab?"
                  id="users"
                  element={<Wizard {...props} {...globalProps} />}
                />
              </Routes>
            </div>
          </Content>
        </Layout>
      </Layout>
    </TagsProvider>
  );
}

export default Admin;
