import { gql } from "@apollo/client";

export const adminApplications = gql`
  query adminApplications(
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $search: String
    $tag: [String]
  ) {
    adminApplications(
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
      tag: $tag
    ) {
      data {
        id
        short_id
        unique_id
        first_name
        last_name
        tags
        email
        phone
        dob
        ssn
        address
        address_line_two
        city
        state
        zip
        was_cancelled
        audit {
          account_date
        }
        user {
          id
          name
          email
        }
        company {
          id
          name
        }
        createdAt
      }
      total
      pageTotal
    }
  }
`;

export const adminSubscriptions = gql`
  query adminSubscriptions(
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $search: String
    $category: String
    $tag: [String]
    $unread: Boolean
  ) {
    adminSubscriptions(
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
      category: $category
      tag: $tag
      unread: $unread
    ) {
      data {
        id
        short_id
        unique_id
        status
        first_name
        last_name
        tags
        email
        phone
        dob
        ssn
        address
        address_line_two
        city
        state
        zip
        reason
        total_unread
        audit {
          subscribed_date
        }
        user {
          id
          name
          email
        }
        company {
          id
          name
        }
        unread {
          counts {
            call
            email
            text
          }
        }
      }
      total
      pageTotal
    }
  }
`;

export const adminApplication = gql`
  query adminApplication($id: ID) {
    adminApplication(id: $id) {
      id
      unique_id
      status
      first_name
      last_name
      email
      phone
      ssn_last_4
      address
      address_line_two
      city
      state
      zip
      plaid

      active_bank_account
      payment_entry_type
      was_cancelled

      unread {
        counts {
          email
          call
          text
        }
        total_unread
      }

      plan {
        type
        amount
        service_percent
        monthly_fees
        debt_amount
        months
        start_date
      }
    }
  }
`;

export const adminForm = gql`
  query adminApplication($id: ID) {
    adminApplication(id: $id) {
      id
      first_name
      last_name
      email
      phone
      address
      address_line_two
      city
      state
      zip
      dob
      ssn_last_4
    }
  }
`;

export const adminSSN = gql`
  query adminApplication($id: ID) {
    adminApplication(id: $id) {
      ssn
    }
  }
`;

export const getActionsData = gql`
  query getActionsData($application_id: ID) {
    getActionsData(application_id: $application_id) {
      application {
        id
        first_name
        last_name
        email
        phone
        address
        address_line_two
        city
        state
        zip
      }
      payments {
        id
        status
      }
    }
  }
`;

export const getCustomerCounts = gql`
  query {
    getCustomerCounts {
      active
      paused
      cancelled
      modifying
    }
  }
`;
