import React from "react";
import { pageMap } from "../../_helpers";
import { Flex, InputLabel, FormInput, Select } from "_styleguide";
import { IconTrash, IconPlus } from "@tabler/icons-react";
import { Modal, Tooltip, Button } from "@centrate-io/barn";
import "./Screening.scss";

function Screening(props) {
  const { settings, us } = props;

  const s = settings.settings.screening;

  const updateQuestion = (f, i, v) => {
    const n = [...s.questions];
    n[i][f] = v;
    us("screening", "questions", n);
  };

  const removeQuestion = (i) => {
    Modal.confirm({
      title: "Are you sure you want remove this Question?",
      okText: "Remove Question",
      onOk: async () => {
        const n = [...s.questions];
        n.splice(i, 1);
        us("screening", "questions", n);
      },
    });
  };

  const addQuestion = () => {
    const n = [...s.questions];
    n.push({ question: "", accepted_response: "", options: [] });
    us("screening", "questions", n);
  };

  return (
    <div className="se-form screening-settings">
      <h3>{pageMap["screening"]?.title}</h3>
      <div className="se-form-items">
        {s.questions?.map((q, i) => {
          return (
            <div key={i}>
              <Flex vertical style={{ flex: 1 }} gap="24px">
                <Flex vertical>
                  <div className="se-sub-header">
                    Question #{i + 1}
                    <Tooltip title="Remove Question">
                      <span
                        className="se-action-icon"
                        onClick={() => removeQuestion(i)}
                      >
                        <IconTrash />
                      </span>
                    </Tooltip>
                  </div>
                  <InputLabel>Title</InputLabel>
                  <FormInput
                    value={q.question}
                    onChange={(e) =>
                      updateQuestion("question", i, e.target.value)
                    }
                    size="medium"
                    placeholder="Question"
                  />
                </Flex>
                <Flex vertical>
                  <InputLabel>
                    Possible Responses
                    <Tooltip title="Add Option">
                      <span
                        className="se-action-icon"
                        onClick={() => {
                          const newOptions = [...(q.options || []), ""];
                          updateQuestion("options", i, newOptions);
                        }}
                      >
                        <IconPlus />
                      </span>
                    </Tooltip>
                  </InputLabel>
                  {(q.options || []).map((option, optionIndex) => (
                    <Flex
                      key={optionIndex}
                      align="center"
                      gap="8px"
                      style={{ marginBottom: "8px" }}
                    >
                      <FormInput
                        value={option}
                        onChange={(e) => {
                          const newOptions = [...(q.options || [])];
                          newOptions[optionIndex] = e.target.value;
                          updateQuestion("options", i, newOptions);
                        }}
                        size="medium"
                        placeholder={`Option ${optionIndex + 1}`}
                      />
                      <Tooltip title="Remove Option">
                        <span
                          className="se-action-icon"
                          onClick={() => {
                            const newOptions = [...(q.options || [])];
                            newOptions.splice(optionIndex, 1);
                            updateQuestion("options", i, newOptions);
                          }}
                        >
                          <IconTrash />
                        </span>
                      </Tooltip>
                    </Flex>
                  ))}
                </Flex>
                <Flex vertical>
                  <InputLabel>Accepted Response</InputLabel>
                  <Select
                    value={q.accepted_response}
                    onChange={(value) =>
                      updateQuestion("accepted_response", i, value)
                    }
                    size="extra-medium"
                    placeholder="Select an option"
                    options={q.options.map((option) => ({
                      label: option,
                      value: option,
                    }))}
                  />
                </Flex>
              </Flex>
              <div className="se-divider"></div>
            </div>
          );
        })}
        <Flex align="center" justify="center">
          <Button
            type="secondary"
            onClick={() => addQuestion()}
            className="boxed"
          >
            Add Question
          </Button>
        </Flex>
      </div>
    </div>
  );
}

export default Screening;
