import React, { useState } from "react";
import {
  IconClipboardText,
  IconCreditCard,
  // IconReport,
  IconFileTypePdf,
  IconBuildingBank,
  IconCoin,
  IconNote,
  IconActivity,
  IconSubtask,
  IconMessage,
  IconPhone,
  IconMail,
  IconSpeakerphone,
  IconChevronDown,
  IconMailbox,
} from "@tabler/icons-react";
import { Menu } from "antd";
import Information from "../Information/Information";
import Tradelines from "../Tradelines/Tradelines";
// import CreditReports from "../CreditReports/CreditReports";
import BankAccounts from "../BankAccounts/BankAccounts";
import Payments from "../Payments/Payments";
import Calls from "../Calls/Calls";
import Texts from "../Texts/Texts";
import Email from "../Email/Email";
import Mail from "../Mail/Mail";
import Notes from "../Notes/Notes";
import Activity from "../Activity/Activity";
import Documents from "../Documents/Documents";
import Tasks from "../Tasks/Tasks";
import { useNavigate } from "react-router-dom";
import { Tag } from "_styleguide";
import "./Navigation.scss";

function Navigation(props) {
  let navigate = useNavigate();
  const [tab, setTab] = useState(props?.default || "tasks");
  const { status } = props;
  const abstractProps = {
    applicationId: props.applicationId,
    shortId: props.shortId,
    setTab: setTab,
    user: props.user,
    app: props.appData,
    status: props.status,
    reloadApplication: props.reloadApplication,
    readOnly: props.readOnly,
  };
  const isDisabled = status === "application";

  // useEffect(() => {
  //   if (tab !== props.default) {
  //     setTab(props.default);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.default]);

  let items = [
    {
      key: "tasks",
      label: (
        <div className="tab-name">
          <IconSubtask />
          Tasks
        </div>
      ),
    },
    {
      key: "information",
      label: (
        <div className="tab-name">
          <IconClipboardText />
          Information
        </div>
      ),
    },
    {
      key: "communication",
      label: (
        <div className="tab-name">
          <IconSpeakerphone />
          Communication
          {props.unread?.total_unread > 0 ? (
            <Tag size="small" type="danger">
              {props.unread?.total_unread}
            </Tag>
          ) : null}
          <IconChevronDown />
        </div>
      ),
      children: [
        {
          key: "emails",
          label: (
            <div className="tab-name">
              <IconMail />
              Emails
              {props.unread?.counts?.email > 0 ? (
                <Tag size="small" type="danger">
                  {props.unread?.counts?.email}
                </Tag>
              ) : null}
            </div>
          ),
        },
        {
          key: "calls",
          label: (
            <div className="tab-name">
              <IconPhone />
              Calls
              {props.unread?.counts?.call > 0 ? (
                <Tag size="small" type="danger">
                  {props.unread?.counts?.call}
                </Tag>
              ) : null}
            </div>
          ),
        },
        {
          key: "texts",
          label: (
            <div className="tab-name">
              <IconMessage />
              Texts
              {props.unread?.counts?.text > 0 ? (
                <Tag size="small" type="danger">
                  {props.unread?.counts?.text}
                </Tag>
              ) : null}
            </div>
          ),
        },
        {
          key: "mail",
          label: (
            <div className="tab-name">
              <IconMailbox />
              Mail
            </div>
          ),
        },
      ],
    },
    {
      key: "tradelines",
      label: (
        <div className="tab-name">
          <IconCreditCard />
          Credit Lines
        </div>
      ),
    },
    // {
    //   key: "credit-reports",
    //   label: (
    //     <div className="tab-name">
    //       <IconReport />
    //       Credit Reports
    //     </div>
    //   ),
    //   children: <CreditReports {...abstractProps} />,
    // },
    {
      key: "payments",
      label: (
        <div className="tab-name">
          <IconCoin />
          Payments
        </div>
      ),
    },
    {
      key: "documents",
      label: (
        <div className="tab-name">
          <IconFileTypePdf />
          Documents
        </div>
      ),
    },
    {
      key: "bank-accounts",
      label: (
        <div className="tab-name">
          <IconBuildingBank />
          Payment Accounts
        </div>
      ),
    },
    {
      key: "notes",
      label: (
        <div className="tab-name">
          <IconNote />
          Notes
        </div>
      ),
    },
    {
      key: "activity",
      label: (
        <div className="tab-name">
          <IconActivity />
          Activity
        </div>
      ),
    },
  ];

  if (isDisabled) {
    items = [
      {
        key: "tasks",
        label: (
          <div className="tab-name">
            <IconSubtask />
            Tasks
          </div>
        ),
      },
      {
        key: "information",
        label: (
          <div className="tab-name">
            <IconClipboardText />
            Information
          </div>
        ),
      },
      {
        key: "communication",
        label: (
          <div className="tab-name">
            <IconSpeakerphone />
            Communication
            {props.unread?.total_unread > 0 ? (
              <Tag size="small" type="danger">
                {props.unread?.total_unread}
              </Tag>
            ) : null}
            <IconChevronDown />
          </div>
        ),
        children: [
          {
            key: "emails",
            label: (
              <div className="tab-name">
                <IconMail />
                Emails
                {props.unread?.counts?.email > 0 ? (
                  <Tag size="small" type="danger">
                    {props.unread?.counts?.email}
                  </Tag>
                ) : null}
              </div>
            ),
          },
          {
            key: "calls",
            label: (
              <div className="tab-name">
                <IconPhone />
                Calls
                {props.unread?.counts?.call > 0 ? (
                  <Tag size="small" type="danger">
                    {props.unread?.counts?.call}
                  </Tag>
                ) : null}
              </div>
            ),
          },
          {
            key: "texts",
            label: (
              <div className="tab-name">
                <IconMessage />
                Texts
                {props.unread?.counts?.text > 0 ? (
                  <Tag size="small" type="danger">
                    {props.unread?.counts?.text}
                  </Tag>
                ) : null}
              </div>
            ),
          },
          {
            key: "mail",
            label: (
              <div className="tab-name">
                <IconMailbox />
                Mail
              </div>
            ),
          },
        ],
      },
      {
        key: "documents",
        label: (
          <div className="tab-name">
            <IconFileTypePdf />
            Documents
          </div>
        ),
      },
      {
        key: "notes",
        label: (
          <div className="tab-name">
            <IconNote />
            Notes
          </div>
        ),
      },
      {
        key: "activity",
        label: (
          <div className="tab-name">
            <IconActivity />
            Activity
          </div>
        ),
      },
    ];
  }

  return (
    <div className="admin-menu">
      <div className="appl-menu">
        <Menu
          mode="horizontal"
          onClick={({ key }) => {
            navigate(`/customer/${props.shortId}/${key}`);
            setTab(key);
          }}
          selectedKeys={[tab]}
          items={items}
        />
      </div>
      <div className="admin-app-content">
        {tab === "tasks" ? <Tasks {...abstractProps} /> : null}
        {tab === "information" ? <Information {...abstractProps} /> : null}
        {tab === "tradelines" ? <Tradelines {...abstractProps} /> : null}
        {tab === "payments" ? <Payments {...abstractProps} /> : null}
        {tab === "calls" ? <Calls {...abstractProps} /> : null}
        {tab === "texts" ? <Texts {...abstractProps} /> : null}
        {tab === "emails" ? <Email {...abstractProps} /> : null}
        {tab === "mail" ? <Mail {...abstractProps} /> : null}
        {tab === "documents" ? <Documents {...abstractProps} /> : null}
        {tab === "bank-accounts" ? <BankAccounts {...abstractProps} /> : null}
        {tab === "notes" ? <Notes {...abstractProps} /> : null}
        {tab === "activity" ? <Activity {...abstractProps} /> : null}
      </div>
    </div>
  );
}

export default Navigation;
