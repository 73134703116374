import React, { useState } from "react";
import BluerockPNG from "_assets/logos/bluerock.png"; // You'll need to add this SVG
import { IconPlugConnected } from "@tabler/icons-react";
import { Button } from "@centrate-io/barn";
import BlueRockSetup from "../_modals/BlueRockSetup/BlueRockSetup"; // You'll need to create this component
import axios from "axios";
import { callsApi } from "_helpers/api";

const BluerockConnect = (props) => {
  const [modal, setModal] = useState(false);
  return (
    <>
      {modal ? (
        <BlueRockSetup
          reload={props.reload}
          user={props.user}
          removeModal={() => setModal(false)}
        />
      ) : null}
      <Button className="boxed" type="primary" onClick={() => setModal(true)}>
        <IconPlugConnected /> Connect BlueRock
      </Button>
    </>
  );
};

export const callFromBlueRock = async (customer, task) => {
  const response = await axios.post(
    `${callsApi()}/bluerock/call`,
    {
      customer,
      task,
    },
    {
      headers: { Authorization: "JWT " + localStorage.getItem("JWT") },
    }
  );
  console.log("BLUEROCK RESPONSE", response.data);
};

const Bluerock = {
  type: "bluerock",
  category: "call",
  company_only: true,
  contact_only: false,
  logo: BluerockPNG,
  name: "BlueRock",
  description:
    "Setup your BlueRock account to enable using BlueRock's phone system.",
  Connect: BluerockConnect,
};

export default Bluerock;
