import React, { useEffect } from "react";
import { IconForms } from "@tabler/icons-react";
import CustomFieldsTable from "./_components/CustomFieldsTable/CustomFieldsTable";
import "./CustomFields.scss";

function CustomFields(props) {
  useEffect(() => {
    props.setCrumbs({
      title: "Custom Fields",
      icon: <IconForms />,
      description:
        "Manage your custom fields to collect additional information from your customers",
    });
    props?.setCurrentCount(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="admin-custom-fields">
      <CustomFieldsTable {...props} />
    </div>
  );
}

export default CustomFields;
