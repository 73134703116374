import { useState } from "react";
import { Button, message, Modal } from "@centrate-io/barn";
import { savePostgrid } from "_graphql/mutations/integration";
import { getErrors } from "_helpers/api";
import { IconMailbox } from "@tabler/icons-react";
import { FormInput, InputLabel, Flex } from "_styleguide";
import { CallsClient } from "_graphql/clients/CallsClient";
import "./PostgridSetup.scss";

function PostgridSetup(props) {
  const callsClient = CallsClient;
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [apiKey, setApiKey] = useState(undefined);

  const setupPostgrid = async () => {
    setSaving(true);
    try {
      await callsClient.mutate({
        variables: {
          api_key: apiKey,
        },
        mutation: savePostgrid,
      });
      message.success(`Postgrid integration setup successfully`);
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(
        getErrors(err) || "Error setting up integration, try again"
      );
      setSaving(false);
    }
  };

  return (
    <Modal
      wrapClassName="postgrid-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      <div className="pp-modal-header">
        <div>
          <IconMailbox />
        </div>
        <h3>Setup Postgrid</h3>
      </div>

      <Modal.Body>
        <Flex vertical={true}>
          <InputLabel htmlFor="apiKey">Live API Key *</InputLabel>
          <FormInput
            id="apiKey"
            value={apiKey}
            disabled={saving}
            onChange={(e) => setApiKey(e.target.value)}
            placeholder="e.g. live_sk_XXXXXXXXXXXXXXX"
            size="medium"
          />
        </Flex>
      </Modal.Body>

      <Modal.Actions>
        <Button block type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button
          block
          type="primary"
          disabled={apiKey?.length <= 0}
          onClick={setupPostgrid}
          loading={saving}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default PostgridSetup;
