import { Tag } from "_styleguide";
import { capitalize } from "_assets/js/helpers";

export const actionTypes = (integrations) => {
  // const hasIntegration = (type) => {
  //   let integration = integrations?.[type]?.active ? true : false;
  //   if (integration) return true;
  //   integration = integrations?.others?.find((i) => i.type === type)?.active;
  //   return integration ? true : false;
  // };

  return [
    {
      name: "Tag",
      value: "TAG",
      next: {
        name: "Action",
        options: [
          {
            name: "Add",
            value: "ADD",
            next: {
              name: "Tag",
              options: "TAG_OPTIONS",
            },
          },
          {
            name: "Remove",
            value: "REMOVE",
            next: {
              name: "Tag",
              options: "TAG_OPTIONS",
            },
          },
          {
            name: "Clear Tags",
            value: "CLEAR",
          },
        ],
      },
    },
    {
      name: "Workflow",
      value: "WORKFLOW",
      next: {
        name: "Action",
        options: [
          {
            name: "Apply",
            value: "APPLY",
            next: {
              name: "Workflow",
              options: "WORKFLOW_OPTIONS",
            },
          },
        ],
      },
    },
    {
      name: "Task",
      value: "TASK",
      next: {
        name: "Action",
        options: [
          {
            name: "Add Task",
            value: "ADD",
            next: {
              name: "Task",
              options: "TASK_OPTIONS",
              custom_options: [
                {
                  name: "Assign to",
                  type: "ASSIGN_OPTIONS",
                  required: true,
                },
              ],
            },
          },
        ],
      },
    },
    // {
    //   name: "Integration",
    //   value: "INTEGRATION",
    //   next: {
    //     name: "Action",
    //     options: [
    //       {
    //         name: "Send to Forth",
    //         value: "FORTH",
    //         disabled: !hasIntegration("webhook-leads-forth")
    //           ? "Integration not connected"
    //           : null,
    //       },
    //     ],
    //   },
    // },
  ];
};

export const actionsFriendly = {
  TAG_ADD: "Add Tag",
  TAG_REMOVE: "Remove Tag",
  TAG_CLEAR: "Clear Tags",
  WORKFLOW_APPLY: "Apply Workflow",
  TASK_ADD: "Add Task",
  INTEGRATION_FORTH: "Send to Forth",
};

export const requiresRef = [
  "TAG_ADD",
  "TAG_REMOVE",
  "WORKFLOW_APPLY",
  "TASK_ADD",
];

export const requiresCustom = {
  TASK_ADD: ["assign_id", "assign_type"],
};

export const friendlyAction = (actionKey, actionId, options, customOptions) => {
  const type = actionKey?.split("_")?.[0];
  if (type === "TAG" && actionsFriendly[actionKey || ""]) {
    const tagInfo = options?.tags?.find((t) => t.id === actionId);
    return tagInfo ? (
      <Tag type="task">
        {actionsFriendly[actionKey || ""]} ({tagInfo.description})
      </Tag>
    ) : (
      <Tag type="task">{actionsFriendly[actionKey || ""]}</Tag>
    );
  } else if (type === "WORKFLOW" && actionsFriendly[actionKey || ""]) {
    const workflowInfo = options?.workflows?.find((w) => w.id === actionId);
    return workflowInfo ? (
      <Tag type="task">
        {actionsFriendly[actionKey || ""]} ({workflowInfo.name} -{" "}
        {workflowInfo.total_tasks} task
        {workflowInfo.total_tasks === 1 ? "" : "s"})
      </Tag>
    ) : (
      <Tag type="task">{actionsFriendly[actionKey || ""]}</Tag>
    );
  } else if (type === "TASK" && actionsFriendly[actionKey || ""]) {
    const taskInfo = options?.templates?.find((t) => t.id === actionId);
    return taskInfo ? (
      <Tag type="task">
        {actionsFriendly[actionKey || ""]} (
        {`${taskInfo.title} -${capitalize(taskInfo.type)}`})
      </Tag>
    ) : (
      <Tag type="task">{actionsFriendly[actionKey || ""]}</Tag>
    );
  } else if (type === "INTEGRATION" && actionsFriendly[actionKey || ""]) {
    return <Tag type="task">{actionsFriendly[actionKey || ""]}</Tag>;
  }
  return null;
};

export const allActions = Object.keys(actionsFriendly);

export const isArray = (variable) => {
  return Array.isArray(variable) && typeof variable !== "string";
};
