import React from "react";
import { pageMap } from "../../_helpers";
import { Flex, InputLabel, FormInput } from "_styleguide";

function CreditSelect(props) {
  const { settings, us } = props;

  const s = settings.settings.credit_select;

  return (
    <div className="se-form">
      <h3>{pageMap["credit_select"]?.title}</h3>
      <Flex vertical gap="24px" className="se-form-items">
        <Flex vertical>
          <InputLabel htmlFor="title">
            Page Title: <span>Use {`{{amount}}`} as total debt</span>
          </InputLabel>
          <FormInput
            id="title"
            value={s.title}
            onChange={(e) => us("credit_select", "title", e.target.value)}
            size="medium"
          />
        </Flex>
        <Flex vertical>
          <InputLabel htmlFor="verify_title">Sub Title:</InputLabel>
          <FormInput
            id="verify_title"
            value={s.verify_title}
            onChange={(e) =>
              us("credit_select", "verify_title", e.target.value)
            }
            size="medium"
          />
        </Flex>
        <Flex vertical>
          <InputLabel htmlFor="minimum_selected_amount">
            Minimum Selected Debt Amount:
          </InputLabel>
          <FormInput
            precision={0}
            id="minimum_selected_amount"
            value={s.minimum_selected_amount}
            onChange={(e) =>
              us(
                "credit_select",
                "minimum_selected_amount",
                parseInt(e.target.value)
              )
            }
            size="medium"
          />
        </Flex>
        <Flex vertical>
          <InputLabel htmlFor="minimum_debt_amount">
            Minimum Available Debt Amount:
          </InputLabel>
          <FormInput
            precision={0}
            id="minimum_debt_amount"
            value={s.minimum_debt_amount}
            onChange={(e) =>
              us(
                "credit_select",
                "minimum_debt_amount",
                parseInt(e.target.value)
              )
            }
            size="medium"
          />
        </Flex>
      </Flex>
    </div>
  );
}

export default CreditSelect;
