export const getErrors = (error) => {
  if (error?.response?.data?.error) {
    return error?.response?.data?.error;
  } else if (error && error.message) {
    return error.message.replace("GraphQL error: ", "");
  } else if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
    return error.graphQLErrors[0].message.replace("GraphQL error: ", "");
  } else {
    return null;
  }
};

export const baseApi = () => {
  const baseMap = {
    localhost: "http://localhost:5000",
    "app.paypath.ai": "https://api.paypath.ai",
    "apply.paypath.ai": "https://api.paypath.ai",
    "app.paypath.dev": "https://api.paypath.dev",
    "apply.paypath.dev": "https://api.paypath.dev",
    "apply-new.paypath.dev": "https://api.paypath.dev",
    "horizonfinancialservices.paypath.ai": "https://hfs-api.paypath.ai",
  };
  const actualApiPath =
    baseMap[window.location.hostname] || "https://api.paypath.ai";
  return actualApiPath;
};

export const callsApi = () => {
  const callsMap = {
    localhost: "http://localhost:5001",
    "app.paypath.ai": "https://calls.paypath.ai",
    "apply.paypath.ai": "https://calls.paypath.ai",
    "app.paypath.dev": "https://calls.paypath.dev",
    "apply.paypath.dev": "https://calls.paypath.dev",
    "apply-new.paypath.dev": "https://calls.paypath.dev",
    "horizonfinancialservices.paypath.ai": "https://hfs-calls.paypath.ai",
  };
  const actualApiPath =
    callsMap[window.location.hostname] || "https://calls.paypath.ai";

  return actualApiPath;
};
