import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { adminForm, adminSSN } from "App/Admin/_graphql/queries/application";
import {
  Loading,
  FormInput,
  InputLabel,
  Select,
  Autocomplete,
  DatePicker,
} from "_styleguide";
import { FormatPhone, FormatSSN } from "_assets/js/helpers";
import { validateAll } from "_helpers/validateForm";
import { STATES } from "_helpers/location";
import { Button, message } from "@centrate-io/barn";
import { saveForm } from "_styleguide/Actions/saveForm";
import { getErrors } from "_helpers/api";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import { IconEdit } from "@tabler/icons-react";
import { applicationCustomFields } from "App/Admin/_graphql/queries/custom_field";
import { saveApplicationCustomFields } from "App/Admin/_graphql/mutations/custom_field";
import CustomFieldsInputs from "App/Admin/Settings/CustomFields/_components/CustomFieldsInputs/CustomFieldsInputs";
import "./Information.scss";

dayjs.extend(dayjsPluginUTC);

function Information(props) {
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [customFieldsLoading, setCustomFieldsLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState();
  const [savedForm, setSavedForm] = useState();
  const [savedCustomFields, setSavedCustomFields] = useState();
  const [showMasked, setShowMasked] = useState(true);
  const [SSNMasked, setSSNMasked] = useState();
  const [ssn, setSsn] = useState();
  const [dob, setDob] = useState();
  const [hasChanged, setHasChanged] = useState(false);
  const [customFields, setCustomFields] = useState([]);

  const u = (f, v) => {
    const n = { ...form };
    if (f === "phone") {
      n[f] = FormatPhone(v);
    } else if (f === "ssn") {
      n[f] = FormatSSN(v);
    } else {
      n[f] = v;
    }
    setForm(n);
  };

  const formatInitialDate = (dateFrom) => {
    let nextValue = undefined;
    if (dateFrom) {
      if (dateFrom?.length <= 13) {
        nextValue = dayjs(parseInt(dateFrom)).utc();
      } else {
        nextValue = dayjs(dateFrom).utc();
      }
      return nextValue;
    } else {
      return undefined;
    }
  };

  const ua = (oldForm, values) => {
    setForm({ ...oldForm, ...values });
  };

  const uc = (index, v) => {
    const n = [...customFields];
    n[index].value = v;
    setCustomFields(n);
  };

  // Load Entries
  const fetchApplication = async () => {
    const applicationData = await client.query({
      variables: { id: props?.applicationId },
      query: adminForm,
    });
    const app = applicationData?.data?.adminApplication;
    const appForm = {
      ...app,
    };
    if (appForm.ssn_last_4?.length === 4) {
      setSSNMasked(`XXX-XX-${appForm.ssn_last_4}`);
      delete appForm.ssn_last_4;
    } else {
      setSSNMasked(undefined);
    }
    if (appForm.dob) {
      setDob(formatInitialDate(appForm.dob));
      delete appForm.dob;
    }
    setShowMasked(true);
    setSsn(undefined);
    setForm(appForm);
    setSavedForm(JSON.stringify(appForm));
    setLoading(false);
  };

  const getApplicationCustomFields = async () => {
    const response = await client.query({
      variables: { id: props?.applicationId },
      query: applicationCustomFields,
    });
    setCustomFields(response?.data?.applicationCustomFields);
    setSavedCustomFields(
      JSON.stringify(response?.data?.applicationCustomFields)
    );
    setCustomFieldsLoading(false);
  };

  const fetchSSN = async () => {
    const applicationData = await client.query({
      variables: { id: props?.applicationId },
      query: adminSSN,
    });
    setShowMasked(false);
    const ssnData = applicationData?.data?.adminApplication?.ssn;
    setSsn(ssnData ? FormatSSN(ssnData) : undefined);
  };

  const saveCustomFields = async () => {
    try {
      await client.mutate({
        variables: {
          application_id: props?.applicationId,
          fields: customFields.map((field) => ({
            id: field.data.id,
            name: field.data.name,
            value: field.value,
          })),
        },
        mutation: saveApplicationCustomFields,
      });
    } catch (err) {
      message.error(getErrors(err) || "Error saving custom fields");
    }
  };
  const saveApplication = async (form) => {
    setSaving(true);
    try {
      const savedForm = {
        ...form,
      };
      if (ssn?.length === 11) {
        savedForm.ssn = ssn.replaceAll("-", "");
        savedForm.ssn_last_4 = ssn?.slice(-4);
      }
      if (dob) {
        savedForm.dob = dob;
      }
      await saveForm(client, { ...savedForm }, "admin");
      await saveCustomFields();
      await fetchApplication();
      await getApplicationCustomFields();
      setSaving(false);
      message.success("Application saved");
    } catch (err) {
      message.error(getErrors(err) || "Error saving application, try again");
      setSaving(false);
    }
  };

  useEffect(() => {
    fetchApplication();
    getApplicationCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validation = validateAll(form);
  const isEdited = JSON.stringify(form) !== savedForm;
  const isCustomFieldsEdited =
    JSON.stringify(customFields) !== savedCustomFields;
  const isValid = validation?.allValid ? true : false;

  const dateFormat = "MM/DD/YYYY";
  const dateChange = async (v) => {
    setHasChanged(true);
    setDob(v);
  };

  return loading || !form || customFieldsLoading ? (
    <Loading />
  ) : (
    <div className="admin-application-information">
      <div className="admin-information-boxes">
        {!props.readOnly ? (
          <div className="admin-bar admin-button">
            {isEdited && <p className="is-edited">Edited</p>}

            <Button
              disabled={
                (!isEdited || !isValid) && !hasChanged && !isCustomFieldsEdited
              }
              loading={saving}
              type="primary"
              onClick={() => saveApplication(form)}
            >
              Save Application
            </Button>
          </div>
        ) : null}
        <div className="wzrd-box">
          <div className="wzrd-header">
            <h3>User Information</h3>
          </div>
          <div className="wzrd-body">
            <div className="form-row">
              <div>
                <InputLabel htmlFor="first_name" {...validation.first_name}>
                  First name *
                </InputLabel>
                <FormInput
                  id="first_name"
                  value={form.first_name}
                  size="medium"
                  onChange={(e) => u("first_name", e.target.value)}
                  placeholder="e.g. James..."
                  {...validation.first_name}
                />
              </div>
              <div>
                <InputLabel htmlFor="last_name" {...validation.last_name}>
                  Last name *
                </InputLabel>
                <FormInput
                  id="last_name"
                  value={form.last_name}
                  size="medium"
                  onChange={(e) => u("last_name", e.target.value)}
                  placeholder="e.g. Ford..."
                  {...validation.last_name}
                />
              </div>
            </div>
            <div className="form-row">
              <div>
                <InputLabel htmlFor="dob">Date of Birth</InputLabel>
                <DatePicker
                  value={dob}
                  placeholder="Select date..."
                  onChange={(val) => dateChange(val)}
                  format={dateFormat}
                  allowClear={false}
                  size="medium"
                />
              </div>
              <div>
                <InputLabel htmlFor="ssn" {...validation.ssn}>
                  Social Security Number *{" "}
                  {SSNMasked && showMasked ? (
                    <span onClick={fetchSSN}>
                      <IconEdit />
                    </span>
                  ) : null}
                </InputLabel>
                {SSNMasked && showMasked ? (
                  <FormInput
                    id="ssn"
                    value={SSNMasked}
                    size="medium"
                    placeholder="e.g. 123-45-6789..."
                    disabled={true}
                  />
                ) : (
                  <FormInput
                    id="ssn"
                    value={ssn}
                    size="medium"
                    onChange={(e) => {
                      setHasChanged(true);
                      setSsn(FormatSSN(e.target.value));
                    }}
                    placeholder="e.g. 123-45-6789..."
                    {...validation.ssn}
                  />
                )}
              </div>
            </div>
            <div className="form-item">
              <InputLabel htmlFor="email" {...validation.email}>
                Email address *
              </InputLabel>
              <FormInput
                id="email"
                value={form.email}
                onChange={(e) => u("email", e.target.value?.toLowerCase())}
                size="medium"
                placeholder="e.g. jford@gmail.com..."
                {...validation.email}
              />
            </div>
            <div className="form-item">
              <InputLabel htmlFor="phone" {...validation.phone}>
                Primary phone number *
              </InputLabel>
              <FormInput
                id="phone"
                value={form.phone}
                onChange={(e) => u("phone", e.target.value)}
                size="medium"
                placeholder="e.g. 631-294-2293..."
                {...validation.phone}
              />
            </div>
          </div>
        </div>
        <div className="wzrd-box">
          <div className="wzrd-header">
            <h3>Home Address</h3>
          </div>
          <div className="wzrd-body">
            <div className="form-item">
              <InputLabel {...validation.address}>Address *</InputLabel>
              <Autocomplete
                value={form.address}
                onChange={(v) => u("address", v)}
                form={form}
                updateAddress={ua}
                size="medium"
                {...validation.address}
              />
              <FormInput
                id="address_line_two"
                value={form.address_line_two}
                onChange={(e) => u("address_line_two", e.target.value)}
                placeholder="Apartment, suite, unit (optional)"
                size="medium"
                style={{ marginTop: "16px" }}
              />
            </div>
            <div className="form-item">
              <InputLabel htmlFor="city" {...validation.city}>
                City *
              </InputLabel>
              <FormInput
                id="city"
                value={form.city}
                onChange={(e) => u("city", e.target.value)}
                size="medium"
                {...validation.city}
              />
            </div>
            <div className="form-row">
              <div>
                <InputLabel htmlFor="state" {...validation.state}>
                  State *
                </InputLabel>
                <Select
                  value={form.state}
                  onChange={(val) => u("state", val)}
                  size="extra-medium"
                  popupClassName="pp-select-dropdown"
                  {...validation.state}
                >
                  {STATES.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div style={{ maxWidth: "160px" }}>
                <InputLabel htmlFor="zip" {...validation.zip}>
                  Zip *
                </InputLabel>
                <FormInput
                  id="zip"
                  value={form.zip}
                  onChange={(e) => u("zip", e.target.value)}
                  size="medium"
                  maxLength="5"
                  {...validation.zip}
                />
              </div>
            </div>
          </div>
        </div>
        <CustomFieldsInputs customFields={customFields} onChange={uc} />
      </div>
    </div>
  );
}

export default Information;
