import { gql } from "@apollo/client";

export const getLogs = gql`
  query getLogs(
    $page: Int
    $results: Int
    $sortField: String
    $sortOrder: String
    $search: String
  ) {
    getLogs(
      page: $page
      results: $results
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
    ) {
      data {
        id
        type
        category
        sub_category
        result_json
        success
        trigger_id
        application_id
        company_id
        createdAt
        updatedAt
        trigger {
          id
          name
        }
        application {
          id
          short_id
          name
        }
      }
      total
      pageTotal
    }
  }
`;
