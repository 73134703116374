import React, { useState } from "react";
import CompanyLogo from "../../_modals/CompanyLogo/CompanyLogo";
import { FormInput, InputLabel, Flex, SelectFont } from "_styleguide";
import { ColorPicker } from "antd";
import { Button } from "@centrate-io/barn";
import { FormatPhone } from "_assets/js/helpers";
import { IconPhoto } from "@tabler/icons-react";

import "./Theme.scss";

function Theme(props) {
  const { settings, reload, u } = props;
  const [logoModal, setLogoModal] = useState(false);

  const updateLogoUrl = (url) => {
    u("logo_url", url);
  };

  return (
    <div className="se-form se-theme-settings">
      {logoModal ? (
        <CompanyLogo
          reload={reload}
          updateLogoUrl={updateLogoUrl}
          removeModal={() => setLogoModal(false)}
        />
      ) : null}
      <div className="se-form-items">
        <Flex className="se-vertical se-bottom">
          <InputLabel htmlFor="title">Page Title</InputLabel>
          <FormInput
            id="title"
            value={settings.title}
            onChange={(e) => u("title", e.target.value)}
            size="medium"
            placeholder="e.g. My Company"
          />
        </Flex>
        <div className="se-divider"></div>
        <Flex className="se-horizontal">
          <InputLabel htmlFor="logo">Logo</InputLabel>
          <div className={`logo-box ${settings.logo_url ? "has-logo" : ""}`}>
            {settings.logo_url ? (
              <img
                className="company-logo"
                src={settings.logo_url}
                alt="Logo"
              />
            ) : null}
            <Button
              size="small"
              className="boxed"
              type="blanco"
              onClick={() => setLogoModal(true)}
            >
              <IconPhoto />
              {settings?.logo_url ? "Replace Logo" : "Upload Logo"}
            </Button>
          </div>
        </Flex>
        <div className="se-divider"></div>
        <Flex className="se-horizontal se-bottom">
          <InputLabel>Primary Font</InputLabel>
          <SelectFont
            defaultValue={settings.primary_font || ""}
            value={(font) => u("primary_font", font)}
          />
        </Flex>
        <Flex className="se-horizontal se-bottom">
          <InputLabel>Secondary Font</InputLabel>
          <SelectFont
            defaultValue={settings.secondary_font || ""}
            value={(font) => u("secondary_font", font)}
          />
        </Flex>
        <Flex className="se-horizontal">
          <InputLabel>Colors</InputLabel>
          <Flex vertical>
            <ColorPicker
              className="pp-color-picker"
              value={settings.primary_color}
              onChange={(val, css) => u("primary_color", css?.toUpperCase())}
              defaultFormat="hex"
              format="hex"
              showText
              disabledAlpha
            />
            <div className="se-sub-label">Primary Accent</div>
          </Flex>
          <Flex vertical>
            <ColorPicker
              className="pp-color-picker"
              value={settings.secondary_color}
              onChange={(val, css) => u("secondary_color", css?.toUpperCase())}
              defaultFormat="hex"
              format="hex"
              showText
              disabledAlpha
            />
            <div className="se-sub-label">Neutral Grey</div>
          </Flex>
        </Flex>
        <div className="se-divider"></div>
        <Flex className="se-horizontal se-bottom">
          <Flex vertical>
            <InputLabel htmlFor="contact_text">Contact Button Text:</InputLabel>
            <FormInput
              id="contact_text"
              value={settings.contact_text}
              onChange={(e) => u("contact_text", e.target.value)}
              size="medium"
              placeholder="e.g. Call us"
            />
          </Flex>
          <Flex vertical>
            <InputLabel htmlFor="phone_number">Phone Number</InputLabel>
            <FormInput
              id="phone_number"
              value={settings.phone_number}
              onChange={(e) => u("phone_number", FormatPhone(e.target.value))}
              size="medium"
              placeholder="e.g. 123-456-7890"
            />
          </Flex>
        </Flex>
        <Flex vertical>
          <InputLabel htmlFor="redirect_url">Redirect URL</InputLabel>
          <FormInput
            id="redirect_url"
            value={settings.redirect_url}
            onChange={(e) => u("redirect_url", e.target.value)}
            size="medium"
            placeholder="e.g. https://apply.mycompany.com"
          />
        </Flex>
      </div>
    </div>

    // <div className="settings-self-enrollment-theme">

    //   <div className="manage-box">
    //     <div className="manage-header">
    //       <h3>General Settings</h3>
    //     </div>
    //     <div className="manage-body">
    //       <div className="form-item">
    //         <InputLabel htmlFor="logo">Company Logo</InputLabel>
    //         <div className="logo-box">
    //           {settings.logo_url ? (
    //             <img
    //               className="company-logo"
    //               src={settings.logo_url}
    //               alt="Logo"
    //             />
    //           ) : null}
    //           <Button
    //             size="small"
    //             className="boxed"
    //             type="secondary"
    //             onClick={() => setLogoModal(true)}
    //           >
    //             {settings?.logo_url ? "Change Logo" : "Upload Logo"}
    //           </Button>
    //         </div>
    //       </div>
    //       <div className="form-item">
    //         <InputLabel htmlFor="title">Title *</InputLabel>
    //         <FormInput
    //           id="title"
    //           value={settings.title}
    //           onChange={(e) => u("title", e.target.value)}
    //           size="medium"
    //           placeholder="e.g. My Company"
    //         />
    //       </div>
    //       <div className="form-item">
    //         <InputLabel htmlFor="phone_number">Phone Number *</InputLabel>
    //         <FormInput
    //           id="phone_number"
    //           value={settings.phone_number}
    //           onChange={(e) => u("phone_number", FormatPhone(e.target.value))}
    //           size="medium"
    //           placeholder="e.g. 123-456-7890"
    //         />
    //       </div>
    //     </div>
    //   </div>
    //   <div className="manage-box">
    //     <div className="manage-header">
    //       <h3>Theme Settings</h3>
    //     </div>
    //     <div className="manage-body">
    //       <div className="form-item">
    //         <InputLabel htmlFor="button_color">Button Color *</InputLabel>
    //         <div>
    //           <ColorPicker
    //             value={settings.button_color}
    //             onChange={(val, css) => u("button_color", css?.toUpperCase())}
    //             defaultFormat="hex"
    //             format="hex"
    //             showText
    //             disabledAlpha
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default Theme;
