import { useState } from "react";
import PayPathSmall from "_assets/logos/icon.svg";
import { IconPlugConnected, IconFileArrowLeft } from "@tabler/icons-react";
import { Button } from "@centrate-io/barn";
import WebhookLeadsModal from "../_modals/WebhookLeadsModal/WebhookLeadsModal";
import { Tag } from "_styleguide";
import IncomingLeadSetup from "../../Webhooks/_modals/IncomingLeadSetup/IncomingLeadSetup";

const WebhookLeadsConnect = (props) => {
  const [modal, setModal] = useState(false);
  return (
    <>
      {modal ? (
        <IncomingLeadSetup
          reload={props.reload}
          user={props.user}
          removeModal={() => setModal(false)}
        />
      ) : null}
      <Button className="boxed" type="primary" onClick={() => setModal(true)}>
        <IconPlugConnected /> Setup Webhook
      </Button>
    </>
  );
};

const WebhookLeads = {
  type: "webhook-leads",
  category: "webhook",
  company_only: true,
  contact_only: false,
  logo: PayPathSmall,
  name: (
    <span>
      Import Lead
      <Tag type="neutral">
        <IconFileArrowLeft /> Incoming
      </Tag>
    </span>
  ),
  description:
    "Setup the ability to send leads to our API and import them directly to the CRM",
  Connect: WebhookLeadsConnect,
  Modal: WebhookLeadsModal,
};

export default WebhookLeads;
