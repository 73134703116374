import React, { useState, useEffect } from "react";
import { Table, Empty } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { getColumns } from "./_data/Columns";
import { Button, message, Modal } from "@centrate-io/barn";
import ManageCustomField from "./_modals/ManageCustomField/ManageCustomField";
import { IconPlus } from "@tabler/icons-react";
import { getErrors } from "_helpers/api";
import { getCustomFields } from "App/Admin/_graphql/queries/custom_field";
import {
  removeCustomField,
  archiveCustomField,
} from "App/Admin/_graphql/mutations/custom_field";
import "./CustomFieldsTable.scss";

function CustomFieldsTable(props) {
  const client = useApolloClient();
  const [manageModal, setManageModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [customFields, setCustomFields] = useState(null);

  // Load Custom Fields
  const fetchCustomFields = async (newField) => {
    setLoading(true);
    const queryData = await client.query({
      query: getCustomFields,
    });
    const customFields = queryData?.data?.getCustomFields || [];
    setCustomFields(customFields);
    props.setCurrentCount(customFields?.length || 0);
    setLoading(false);
  };

  // Remove Custom Field
  const deleteCustomField = async (fieldId) => {
    Modal.confirm({
      title: "Are you sure you want remove this Custom Field?",
      okText: "Remove Field",
      content: (
        <p>
          <b style={{ fontWeight: 600, color: "#000" }}>Warning:</b>
          <br />
          This will REMOVE this custom field from all forms and any data
          collected with this field.
          <br />
          <br />
          <b style={{ fontWeight: 600, color: "#000" }}>
            This cannot be undone!
          </b>
        </p>
      ),
      onOk: async () => {
        try {
          await client.mutate({
            variables: {
              custom_field_id: fieldId,
            },
            mutation: removeCustomField,
          });
          fetchCustomFields();
          message.success(`Custom Field successfully removed`);
        } catch (err) {
          message.error(
            getErrors(err) || "Error removing custom field, try again"
          );
        }
      },
    });
  };

  // Remove Custom Field
  const disableCustomField = async (fieldId, archived) => {
    Modal.confirm({
      title: `Are you sure you want to ${
        !archived ? "enable" : "disable"
      } this Custom Field?`,
      okText: !archived ? "Enable Field" : "Disable Field",
      content: !archived
        ? "Enabling this field will show it in all forms again."
        : "Disabling this field will hide it from all forms but the data will remain, delete this custom field to remove the data.",
      onOk: async () => {
        try {
          await client.mutate({
            variables: {
              custom_field_id: fieldId,
              archived: archived,
            },
            mutation: archiveCustomField,
          });
          fetchCustomFields();
          message.success(
            `Custom Field successfully ${!archived ? "enabled" : "disabled"}`
          );
        } catch (err) {
          message.error(
            getErrors(err) ||
              `Error ${
                !archived ? "enabling" : "disabling"
              } custom field, try again`
          );
        }
      },
    });
  };

  useEffect(() => {
    fetchCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let tableData = (customFields || []).sort((a, b) => {
    if (a.archived === b.archived) return 0;
    return a.archived ? 1 : -1;
  });
  const columns = getColumns(
    setManageModal,
    deleteCustomField,
    disableCustomField,
    props.readOnly
  );
  const isEmpty = (customFields || [])?.length <= 0;

  return (
    <>
      {manageModal ? (
        <ManageCustomField
          fieldId={manageModal}
          reload={fetchCustomFields}
          removeModal={() => setManageModal(false)}
        />
      ) : null}
      {isEmpty ? (
        <Empty
          title="Create Your First Custom Field"
          description="Custom fields allow you to collect additional information from your customers..."
          action="Create Custom Field"
          actionIcon={<IconPlus />}
          onClick={() => setManageModal("new")}
        />
      ) : (
        <div className="settings-custom-fields">
          <div className="settings-search">
            <div className="ms-actions">
              {!props.readOnly ? (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => setManageModal("new")}
                >
                  <IconPlus />
                  New Field
                </Button>
              ) : null}
            </div>
          </div>
          <Table
            columns={columns}
            loading={loading}
            pagination={{ pageSize: 10, showSizeChanger: false }}
            rowKey={(record) => record.id}
            dataSource={tableData}
            showSorterTooltip={false}
            rowClassName={(record) => (record.archived ? "archived-row" : "")}
            scroll={{
              y: "calc(80vh - 231px)",
              x: "max-content",
            }}
            locale={{
              emptyText: loading
                ? "Loading custom fields..."
                : "No custom fields found",
            }}
          />
        </div>
      )}
    </>
  );
}

export default CustomFieldsTable;
