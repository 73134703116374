import { useState } from "react";
import { Modal, message, Tooltip } from "@centrate-io/barn";
import { IconWebhook } from "@tabler/icons-react";
import { FormInput, InputLabel, Flex, AlertBox } from "_styleguide";
import { CopyOutlined, TeamOutlined } from "@ant-design/icons";
import { baseApi } from "_helpers/api";
import { Collapse } from "antd";
import "./WebhookLeadsModal.scss";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";

function WebhookLeadsModal(props) {
  const [visible, setVisible] = useState(true);
  const { info } = props.settings;

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    message.success("Copied to clipboard!");
  };

  const codeString = `// POST ${baseApi()}/webhook/leads
{
  "Authorization": "Bearer ${info?.API_KEY}",
  "Content-Type": "application/json",
}`;

  const dataString = `{
  "first_name": "John", // Required
  "last_name": "Doe", // Required
  "co_first_name": "Jane",
  "co_last_name": "Doe",
  "email": "john.doe@gmail.com",
  "phone": "555-123-4567",
  "mobile": "555-123-4567",
  "dob": "01/02/2025",
  "ssn_last_4": "6789",
  "ssn": "123-45-6789",
  "address": "123 Paypath St",
  "address_line_two": "APT 12",
  "city": "Buffalo",
  "state": "NY",
  "zip": "14201",
  "unique_id": "YOUR_UNIQUE_ID"
}`;

  const responseString = `// SUCCESS: 
{
  "id" : "eZyXyi5zrrL4",
  "success": true,
  "error": null 
}

//ERROR:
{
  "id": null,
  "success": false,
  "error": "Invalid information, try again" 
}`;

  const axiosExample = `const axios = require('axios');

const data = {
  first_name: "John",
  last_name: "Doe",
  email: "john.doe@gmail.com",
  phone: "555-123-4567"
};

const config = {
  headers: {
    'Authorization': 'Bearer ${info?.API_KEY}',
    'Content-Type': 'application/json'
  }
};

axios.post('${baseApi()}/webhook/leads', data, config)
  .then(response => {
    console.log('Success:', response.data);
  })
  .catch(error => {
    console.error('Error:', error.response?.data);
  });`;

  return (
    <Modal
      wrapClassName="webhook-leads-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={true}
      width={640}
    >
      <Modal.Close onClick={() => setVisible(false)} />

      <div className="pp-modal-header">
        <div>
          <IconWebhook />
        </div>
        <h3>PayPath Webhook (Leads) - Information</h3>
      </div>

      <Modal.Body>
        <Flex vertical={true}>
          <InputLabel>Import leads to:</InputLabel>
          <FormInput
            value={info?.company_name}
            readOnly
            size="large"
            suffix={
              <TeamOutlined
                style={{ cursor: "pointer", color: "#000" }}
                onClick={() => copyToClipboard(info?.API_KEY)}
              />
            }
          />
        </Flex>
        <Flex vertical={true}>
          <InputLabel>API KEY:</InputLabel>
          <FormInput
            value={info?.API_KEY}
            readOnly
            size="large"
            suffix={
              <Tooltip title="Copy">
                <CopyOutlined
                  style={{ cursor: "pointer", color: "#000" }}
                  onClick={() => copyToClipboard(info?.API_KEY)}
                />
              </Tooltip>
            }
          />
        </Flex>

        <Flex vertical={true}>
          <InputLabel>API Information:</InputLabel>
          <Collapse defaultActiveKey={[]} expandIconPosition="end">
            <Collapse.Panel header="Endpoint & Headers" key="1">
              <SyntaxHighlighter
                language="json"
                style={oneLight}
                customStyle={{
                  fontSize: "12px",
                  borderRadius: "8px",
                }}
              >
                {codeString}
              </SyntaxHighlighter>
            </Collapse.Panel>

            <Collapse.Panel header="Example Post Data" key="2">
              <SyntaxHighlighter
                language="json"
                style={oneLight}
                customStyle={{
                  fontSize: "12px",
                  borderRadius: "8px",
                }}
              >
                {dataString}
              </SyntaxHighlighter>
            </Collapse.Panel>

            <Collapse.Panel header="Example Responses" key="3">
              <SyntaxHighlighter
                language="json"
                style={oneLight}
                customStyle={{
                  fontSize: "12px",
                  borderRadius: "8px",
                }}
              >
                {responseString}
              </SyntaxHighlighter>
            </Collapse.Panel>

            <Collapse.Panel header="Code Example: JavaScript" key="4">
              <SyntaxHighlighter
                language="javascript"
                style={oneLight}
                customStyle={{
                  fontSize: "12px",
                  borderRadius: "8px",
                }}
              >
                {axiosExample}
              </SyntaxHighlighter>
            </Collapse.Panel>
          </Collapse>
        </Flex>
        <AlertBox type="info">
          Data sent to this endpoint will be added as a new lead. Any fields
          sent that are not one of the fields listed in <b>Example Post Data</b>{" "}
          will be added in a Note on the lead.
        </AlertBox>
      </Modal.Body>
    </Modal>
  );
}

export default WebhookLeadsModal;
